/* Imports
================================================================================ */
import _ from 'lodash';
import { Role, Workflow } from 'common/types/approvals';
import { fetchSettings, removeReviewer, addReviewer, fetchRoles, setApprovedResubmissionPolicy, setPresetState} from 'common/core/approvals/index_new';
import {
  all,
  call,
  put,
  takeLatest,
  select,
  take
} from 'redux-saga/effects';

import * as Actions from './actions';
import * as Selectors from './selectors';
import * as Types from './types';
import { Approver } from 'common/types/approvals';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */
export const getSaveCalls = (changedSettings: Types.ChangedSettings) => {
  const saveCalls: any[] = [];

  // For each changed task, prepare a save call
  if (changedSettings.tasks.length) {
    changedSettings.tasks.forEach((task) => {
      saveCalls.push({
        fn: setPresetState,
        args: [
          task.id as number,
          task.value as Types.ApprovalEnums.ApprovalState
        ]
      });
    });
  }

  // For each changed workflow, prepare a save call
  if (changedSettings.workflows.length) {
    changedSettings.workflows.forEach((workflow) => {
      saveCalls.push({
        fn: setApprovedResubmissionPolicy,
        args: [
          workflow.id as number,
          workflow.approvedResubmissionPolicy as Types.ApprovalEnums.ResubmissionSettings
        ]
      });
    });
  }

  return saveCalls;
};

export const getAddApproverCalls = (approvers: Approver[], publicWorkflowId: number, internalWorkflowId: number ) => {
  const addApproverCalls: any[] = [];

  // For each approver, create a call for the workflows they are being added to
  if (approvers.length) {
    approvers.forEach((approver) => {
      if (approver.can_review_public) {
        addApproverCalls.push({
          fn: addReviewer,
          args: [
            approver.uid,
            publicWorkflowId
          ]
        });
      }

      if (approver.can_review_internal) {
        addApproverCalls.push({
          fn: addReviewer,
          args: [
            approver.uid,
            internalWorkflowId
          ]
        });
      }
    });
  }

  return addApproverCalls;
};



/* Primary Export
================================================================================ */
export default function* sagas() {
  yield all([
    takeLatest(Actions.FETCH_SETTINGS, loadSettings),
    takeLatest(Actions.REMOVE_ALL_APPROVAL_RIGHTS, removeAllReviewerRights),
    takeLatest(Actions.FETCH_ROLES, loadRoles),
    takeLatest(Actions.SAVE_APPROVAL_SETTINGS, saveSettings),
    takeLatest(Actions.SAVE_NEW_APPROVERS, saveApprovers),
    takeLatest(Actions.EDIT_APPROVER, editApprover)
  ]);
}

/**
 * Add or remove specific workflows based on the diff between the old record and the new approver
 */
export function* editApprover({ payload: { oldApprover, newApprover }}: Actions.EditApproverAction) {
  if (newApprover.can_review_internal === oldApprover.can_review_internal &&
      newApprover.can_review_public === oldApprover.can_review_public) {
        yield put(Actions.editApproverSuccess()); // if nothing actually changed, just say yay you did it and move on
  } else {
    try {
      const publicWorkflowId: number = yield select(Selectors.getPublicWorkflowId);
      const internalWorkflowId: number = yield select(Selectors.getInternalWorkflowId);

      // if the state is different, add or remove reviewer as requested
      if (newApprover.can_review_internal != oldApprover.can_review_internal) {
        if (newApprover.can_review_internal) {
          // right was added and let's go add the right
          const response: Response = yield call(addReviewer, newApprover.uid, internalWorkflowId);
          if (response.status != 200) {
            throw Error(response.statusText);
          }
        } else {
          // right was removed, so let's go remove the right
          const response: Response = yield call(removeReviewer, newApprover.uid, internalWorkflowId);
          if (response.status != 200) {
            throw Error(response.statusText);
          }
        }
      }

      // if the state is different, add or remove reviewer as requested
      if (newApprover.can_review_public != oldApprover.can_review_public) {
        if (newApprover.can_review_public) {
          // right was added and let's go add the right
          const response: Response = yield call(addReviewer, newApprover.uid, publicWorkflowId);
          if (response.status != 200) {
            throw Error(response.statusText);
          }
        } else {
          // right was removed, so let's go remove the right
          const response: Response = yield call(removeReviewer, newApprover.uid, publicWorkflowId);
          if (response.status != 200) {
            throw Error(response.statusText);
          }
        }
      }

      yield put(Actions.editApproverSuccess());
    } catch (error) {
      yield put(Actions.editApproverFailure());
    }
  }
}

/**
 * Add each approver for each workflow they've been assigned. In most cases, involves multiple API calls.
 */
export function* saveApprovers({ payload: { approvers }}: Actions.SaveNewApproversAction) {
  try {
    const publicWorkflowId: number = yield select(Selectors.getPublicWorkflowId);
    const internalWorkflowId: number = yield select(Selectors.getInternalWorkflowId);

    const addApproverCalls = getAddApproverCalls(approvers, publicWorkflowId, internalWorkflowId);
    const result: Response[] = yield all(addApproverCalls.map(({fn, args}) => fn(...args)));
    const wasFailure = _.find(result, function(r) { if (r.status != 200) {return true;}});
    if (wasFailure) throw Error;
    yield put(Actions.saveNewApproversSuccess(approvers));
  } catch (error) {
    yield put(Actions.saveNewApproversFailure());
  }
}

/**
 * Save each changed workflow and task setting, then load the updated settings if saves were successful. Involves multiple API calls.
 */
export function* saveSettings() {
  const changedSettings: Types.ChangedSettings = (yield select(Selectors.getChangedSettings));
  const saveCalls: any[] = getSaveCalls(changedSettings);

  try {
    yield all(saveCalls.map(({ fn, args }) => fn(...args)));

    // Refetch our newly saved settings and report the success
    const settings: Workflow[] = yield call(fetchSettings);
    yield put(Actions.saveApprovalSettingsSuccess(settings));
  } catch (error) {
    // Report any issues
    yield put(Actions.saveApprovalSettingsFailure(error));
  }
}

/**
 * Gather information about both approvals settings and approvers. Involves two API calls.
 */
export function* loadSettings() {
  try {
    yield put(Actions.fetchRoles());
    yield take(Actions.FETCH_ROLES_SUCCESS); // ensure fetchRoles gets finished before continuing to load settings
    const result: Workflow[] = yield call(fetchSettings);
    yield put(Actions.fetchSettingsSuccess(result));
  } catch (error) {
    yield put(Actions.fetchSettingsFailure(error));
  }
}

/**
 * Gather information about roles on the current domain. Used when generating list of approvers
 */
export function* loadRoles() {
  try {
    const roles: Role[] = yield call(fetchRoles);
    yield put(Actions.fetchRolesSuccess(roles));
  } catch (error) {
    yield put(Actions.fetchRolesFailure(error));
  }
}

/**
 * Remove the approver rights from a user for all approval workflows
 */
export function* removeAllReviewerRights({ payload: { approver } }: Actions.RemoveAllApprovalRightsAction) {
  try {
    const publicWorkflowId: number = yield select(Selectors.getPublicWorkflowId);
    const internalWorkflowId: number = yield select(Selectors.getInternalWorkflowId);


    if ((!publicWorkflowId && !internalWorkflowId) ||
        (approver.can_review_public && !publicWorkflowId) ||
        (approver.can_review_internal && !internalWorkflowId)) {
      yield put(Actions.removeAllApprovalRightsFailure());
    }

    if (approver.can_review_public) {
      const response: Response = yield call(removeReviewer, approver.uid, publicWorkflowId);
      if (response.status != 200) {
        throw Error(response.statusText);
      }
    }

    if (approver.can_review_internal) {
      const response: Response = yield call(removeReviewer, approver.uid, internalWorkflowId);
      if (response.status != 200) {
        throw Error(response.statusText);
      }
    }

    yield put(Actions.removeAllApprovalRightsSuccess(approver.uid));
  } catch (error) {
    yield put(Actions.removeAllApprovalRightsFailure());
  }
}
