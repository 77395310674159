/* Imports
================================================================================ */
import { get, flow, find, forEach, flatMap, upperFirst  } from 'lodash';

import {
  ApprovalState,
  ResubmissionSettings,
  WorkflowTargetAudience,
} from 'common/core/approvals_enums';
import FeatureFlags from 'common/feature_flags';
import { Approver, Workflow } from 'common/types/approvals';

import * as Types from './types';





/* Helper Methods
================================================================================ */
/**
 * Determines whether to treat the domain as an EDP domain
 * @returns boolean
 */
export const isEdpDomain = (): boolean => {
  return !!FeatureFlags.valueOrDefault('strict_permissions');
};

/**
 * LoDash-esque method to gather all the values for a given key path within a collection.
 * @param collection Array or Object from which to collect values
 * @param key The dot separated key path to use to get the values (uses LoDash's get method)
 * @param callback (Optional) Function which takes the the resulting array of values as its parameter and should return an array of values.
 * @returns An array of values
 */
export const collect = (collection: any[] | { [key: string]: any }, key: string, callback?: (response: any[]) => any[]): any[] => {
  const response: any[] = [];

  forEach(collection, (item) => {
    const value = get(item, key);

    if (value) response.push(value);
  });

  return typeof callback === 'function' ? callback(response) : response;
};

/**
 * Shortcut method to collect all the task objects from across the workflows and return them in an array
 * @param state An ApprovalSettingsState object
 * @returns An array of task objects
 */
export const collectTasks = (state: Types.ApprovalSettingsState): Types.WorkflowTaskConfigurationData[] => {
  const taskCollections = collect(state.workflows, 'tasks');

  return flatMap(taskCollections, (collection) => Object.values(collection)) as Types.WorkflowTaskConfigurationData[];
};

/**
 * Check each workflow to see if the approved or rejected resubmission policy values has changed
 * @param state An ApprovalSettingsState object
 * @returns An array of workflows which have changed resubmission policy values
 */
export const getChangedResubmissionPolicySettings = (state: Types.ApprovalSettingsState): Types.WorkflowConfigurationData[] => {
  const response: Types.WorkflowConfigurationData[] = [];

  forEach(state.workflows, (workflow) => {
    const approvedResubmissionPolicyChanged = (
      workflow.approvedResubmissionPolicy &&
      workflow.approvedResubmissionPolicy !== (workflow.savedApprovedResubmissionPolicy || workflow.defaultApprovedResubmissionPolicy)
    );

    if (approvedResubmissionPolicyChanged) response.push(workflow);
  });

  return response;
};

/**
 * Check the tasks for each workflow to see if the task's value has changed
 * @param state An ApprovalSettingsState object
 * @returns An array of tasks which have changed values
 */
export const getChangedTaskSettings = (state: Types.ApprovalSettingsState): Types.WorkflowTaskConfigurationData[] => {
  const tasks = collectTasks(state);
  const response: Types.WorkflowTaskConfigurationData[] = [];

  forEach(tasks, (task) => {
    const taskValueChanged = (
      task.value &&
      task.value !== (task.savedValue || task.defaultValue)
    );

    if (taskValueChanged) response.push(task);
  });

  return response;
};

/**
 * Check state to see if any settings have been changed
 * @param state An ApprovalSettingsState object
 * @returns An object listing the changed workflows and the changed tasks
 */
export const getChangedSettings = (state: Types.ApprovalSettingsState): Types.ChangedSettings => {
  return {
    workflows: getChangedResubmissionPolicySettings(state),
    tasks: getChangedTaskSettings(state)
  };
};

/**
 * Check state to see if any settings have been changed
 * @param state An ApprovalSettingsState object
 * @returns True if a value has been changed or False if it hasn't
 */
export const hasChangedSettings = (state: Types.ApprovalSettingsState): boolean => {
  return !!(
    getChangedResubmissionPolicySettings(state).length ||
    getChangedTaskSettings(state).length
  );
};

/**
 * Check the changed tasks to see if their new value would affect pending requests
 * @param changedTasks Array of task objects with changed values
 * @returns Array of tasks which have a new value which would affect pending requests
 */
export const getTaskChangesAffectingPendingRequests = (changedTasks: Types.WorkflowTaskConfigurationData[]): Types.WorkflowTaskConfigurationData[] => {
  const affectingTaskValues: Array<Types.ApprovalEnums.ApprovalState | undefined> = [
    Types.ApprovalEnums.ApprovalState.APPROVED,
    Types.ApprovalEnums.ApprovalState.REJECTED
  ];

  return changedTasks.filter((task) => affectingTaskValues.indexOf(task.value) !== -1);
};

/**
 * Check the changed workflows to see if their new resubmission policy value would affect pending requests
 * @param changedWorkflows Array of workflow objects with changed resubmission policy values
 * @returns Array of workflows which have a new resubmission policy value which would affect pending requests
 */
export const getWorkflowChangesAffectingPendingRequests = (changedWorkflows: Types.WorkflowConfigurationData[]): Types.WorkflowConfigurationData[] => {
  return changedWorkflows.filter((workflow) => workflow.approvedResubmissionPolicy === Types.ApprovalEnums.ResubmissionSettings.MAINTAIN_STATE);
};

/**
 * Check the changed settings to see if any of the changed values would affect pending requests
 * @param changedSettings An object listing the changed workflows and the changed tasks
 * @returns An object listing the changed workflows and the changed tasks which have values that would affect pending requests
 */
export const getChangesAffectingPendingRequests = (changedSettings: Types.ChangedSettings): Types.ChangedSettings => {
  return {
    tasks: getTaskChangesAffectingPendingRequests(changedSettings.tasks),
    workflows: getWorkflowChangesAffectingPendingRequests(changedSettings.workflows)
  };
};





/* Main
================================================================================ */

/**
 * Get the Approval Settings state object
 * @param state The current state store
 * @returns ApprovalSettingsState
 */
export const getApprovalSettings = (state: Types.ApprovalSettingsSelectorStateParam): Types.ApprovalSettingsState => get(state, 'approvalSettings', state);




/* Top Level Selectors
---------------------------------------------------------------------- */
export const getActionButtonsDisabled: Types.ActionButtonsDisabledSelectorFunction = flow(getApprovalSettings, (state: Types.ApprovalSettingsState) => get(state, 'actionButtonsDisabled', false));

export const getUsers = (state: Types.ApprovalSettingsSelectorStateParam): Types.Approvals.Approver[] => get(state, 'users', []);

export const getWorkflows = (state: Types.ApprovalSettingsSelectorStateParam): Types.WorkflowsConfigurationData => get(state, 'workflows');

export const getApiWorkflows = (state: Types.ApprovalSettingsSelectorStateParam): Types.Approvals.Workflow[] => get(state, 'apiWorkflows', []);




/* Other Selectors
---------------------------------------------------------------------- */
/**
 * Given the state and a targetAudience value, return the workflow object from state.apiWorkflows
 * which has a matching targetAudience value
 * @param state The ApprovalSettingsState object
 * @param targetAudience The targetAudience value of the workflow you wish to get back
 * @returns The workflow object which has a matching targetAudience value
 */
export const getApiWorkflowByTargetAudience = (
  state: Types.ApprovalSettingsState,
  targetAudience: Types.ApprovalEnums.WorkflowTargetAudience
): Types.Approvals.Workflow | undefined => {
  return find(state.apiWorkflows, (apiWorkflow) => apiWorkflow.targetAudience === targetAudience);
};

/**
 * Given an apiWorkflow object and a scope value, return the task object from apiWorkflow.steps[0].tasks
 * which has a matching scope value
 * @param apiWorkflow A workflow object from state.apiWorkflows
 * @param scope The scope value of the task you wish to get back
 * @returns The task object which has a matching scope value
 */
export const getApiWorkflowTaskByScope = (
  apiWorkflow: Types.Approvals.Workflow,
  scope: Types.ApprovalEnums.WorkflowTaskScope
): Types.Approvals.WorkflowTask | undefined => {
  return find(apiWorkflow.steps[0].tasks, (apiTask) => apiTask.scope === scope);
};

/**
 * Given the state object, a targetAudience value, and a scope value, return the task object
 * which has a matching scope value from within the apiWorkflow object which has a matching
 * targetAudience value from within state.apiWorkflows
 * @param apiWorkflow A workflow object from state.apiWorkflows
 * @param scope The scope value of the task you wish to get back
 * @returns The task object which has a matching scope value
 */
export const getApiWorkflowTask = (
  state: Types.ApprovalSettingsState,
  targetAudience: Types.ApprovalEnums.WorkflowTargetAudience,
  scope: Types.ApprovalEnums.WorkflowTaskScope
): Types.Approvals.WorkflowTask | undefined => {
  const apiWorkflow = getApiWorkflowByTargetAudience(state, targetAudience);

  return apiWorkflow ? getApiWorkflowTaskByScope(apiWorkflow, scope) : undefined;
};

export const getPublicWorkflowId = (state: Types.ApprovalSettingsSelectorStateParam): number | undefined => {
    const apiWorkflows = getApprovalSettings(state).apiWorkflows;
    const id = find(apiWorkflows, function(w) { return w.targetAudience === 'public';})?.id;
    return id;
};

export const getInternalWorkflowId = (state: Types.ApprovalSettingsSelectorStateParam): number | undefined => {
    const apiWorkflows = getApprovalSettings(state).apiWorkflows;
    const id = find(apiWorkflows, function(w) { return w.targetAudience === 'internal';})?.id;
    return id;
};




/* Get Setting Values
---------------------------------------------------------------------- */
/**
 * Get the value of a resubmission policy attribute within a workflow
 * @param state The current ApprovalSettingsState object
 * @param targetAudience Which workflow target audience to look in
 * @param valueType (Optional, Default '') Which value do we want (saved, default, or '' = the current value)
 * @returns The resubmission policy value
 */
export const getResubmissionPolicyValue = (
  state: Types.ApprovalSettingsState,
  targetAudience: WorkflowTargetAudience,
  valueType = ''
): ResubmissionSettings | undefined => {
  const key = (valueType ? valueType + 'Approved' : 'approved') + 'ResubmissionPolicy';

  return state.workflows[targetAudience][key];
};

/**
 * Get the value of a task within a workflow
 * @param state The current ApprovalSettingsState object
 * @param targetAudience Which workflow target audience to look in
 * @param sectionScope Which task for which to get the value
 * @param valueType (Optional, Default '') Which value do we want (saved, default, or '' = the current value)
 * @returns The task value
 */
export const getTaskValue = (
  state: Types.ApprovalSettingsState,
  targetAudience: WorkflowTargetAudience,
  sectionScope: Types.SectionScope,
  valueType = ''
): ApprovalState | undefined => {
  const key = (valueType ? valueType + 'Value' : 'value');

  return state.workflows[targetAudience].tasks[sectionScope][key];
};

/**
 * Shortcut method to get a setting's current value
 * @param state The current ApprovalSettingsState object
 * @param params _{ sectionScope, itemScope, targetAudience }_
 * @returns The setting current value
 */
export const getCurrentValue = (
  state: Types.ApprovalSettingsState,
  params: Types.GetSettingValueSelectorParams
): ApprovalState | ResubmissionSettings | undefined => {
  const {
    itemScope,
    targetAudience,
    sectionScope
  } = params;

  return itemScope === 'resubmission_policy' ?
    getResubmissionPolicyValue(state, targetAudience) :
    getTaskValue(state, targetAudience, sectionScope);
};

/**
 * Shortcut method to get a setting's default value
 * @param state The current ApprovalSettingsState object
 * @param params _{ sectionScope, itemScope, targetAudience }_
 * @returns The setting's default value
 */
export const getDefaultValue = (
  state: Types.ApprovalSettingsState,
  params: Types.GetSettingValueSelectorParams
): ApprovalState | ResubmissionSettings | undefined => {
  const {
    itemScope,
    targetAudience,
    sectionScope
  } = params;

  return itemScope === 'resubmission_policy' ?
    getResubmissionPolicyValue(state, targetAudience, 'default') :
    getTaskValue(state, targetAudience, sectionScope, 'default');
};

/**
 * Shortcut method to get a setting's last saved value
 * @param state The current ApprovalSettingsState object
 * @param params _{ sectionScope, itemScope, targetAudience }_
 * @returns The setting's last saved value
 */
export const getSavedValue = (
  state: Types.ApprovalSettingsState,
  params: Types.GetSettingValueSelectorParams
): ApprovalState | ResubmissionSettings | undefined => {
  const {
    itemScope,
    targetAudience,
    sectionScope
  } = params;

  return itemScope === 'resubmission_policy' ?
    getResubmissionPolicyValue(state, targetAudience, 'saved') :
    getTaskValue(state, targetAudience, sectionScope, 'saved');
};

/**
 * Shortcut method to get a setting's last saved value (if there is one) or its default value
 * @param state The current ApprovalSettingsState object
 * @param params _{ sectionScope, itemScope, targetAudience }_
 * @returns The setting's last saved value (if there is one) or its default value
 */
export const getSavedOrDefaultValue = (
  state: Types.ApprovalSettingsState,
  params: Types.GetSettingValueSelectorParams
): ApprovalState | ResubmissionSettings | undefined => {
  return getSavedValue(state, params) || getDefaultValue(state, params);
};

/**
 * Shortcut method to get a setting's current value (if there is one) or its default value
 * @param state The current ApprovalSettingsState object
 * @param params _{ sectionScope, itemScope, targetAudience }_
 * @returns The setting's current value (if there is one) or its default value
 */
export const getCurrentOrDefaultValue = (
  state: Types.ApprovalSettingsState,
  params: Types.GetSettingValueSelectorParams
): ApprovalState | ResubmissionSettings | undefined => {
  return getCurrentValue(state, params) || getDefaultValue(state, params);
};

/**
 * Shortcut method to get a setting's current value (if there is one), saved value (if there is one), or its default value
 * @param state The current ApprovalSettingsState object
 * @param params _{ sectionScope, itemScope, targetAudience }_
 * @returns The setting's current value (if there is one), saved value (if there is one), or its default value
 */
export const getCurrentSavedOrDefaultValue = (
  state: Types.ApprovalSettingsState,
  params: Types.GetSettingValueSelectorParams
): ApprovalState | ResubmissionSettings | undefined => {
  return getCurrentValue(state, params) || getSavedValue(state, params) || getDefaultValue(state, params);
};




/* Determination Logic
---------------------------------------------------------------------- */
/**
 * Determine if the resubmission policy setting for a workflow should be disabled on an EDP domain.
 * @param tasks The object of scope keyed tasks from a given workflow
 * @returns boolean
 */
export const edpShouldResubmissionPolicyBeDisabled = (tasks: { [key: string]: Types.WorkflowTaskConfigurationData }): boolean => {
  const {
    official
  } = tasks;

  // EDP Domains - The radio for the "official" section must be set to "approved"
  return ((official.value || official.savedValue || official.defaultValue) === ApprovalState.APPROVED);
};

/**
 * Determine if the resubmission policy setting for a workflow should be disabled on an ODP domain.
 * @param tasks The object of scope keyed tasks from a given workflow
 * @returns boolean
 */
export const odpShouldResubmissionPolicyBeDisabled = (tasks: { [key: string]: Types.WorkflowTaskConfigurationData }): boolean => {
  const {
    official,
    community
  } = tasks;

  // ODP Domains - The radio for the "official" & "community" sections must both be set to "approved"
  return (
    ((official.value || official.savedValue || official.defaultValue) === ApprovalState.APPROVED) &&
    ((community.value || community.savedValue || community.defaultValue) === ApprovalState.APPROVED)
  );
};

/**
 * Routes to the correct determination method based on whether the domain is an EDP domain for the given workflow.
 * @param workflow The workflow for which to determine whether the resubmission policy setting should be disabled.
 * @returns boolean
 */
export const shouldResubmissionPolicyBeDisabled = (workflow: Types.WorkflowConfigurationData): boolean => {
  return isEdpDomain() ?
    edpShouldResubmissionPolicyBeDisabled(workflow.tasks) :
    odpShouldResubmissionPolicyBeDisabled(workflow.tasks);
};

/**
 * Determine whether the resubmission policy settings should be disabled for each workflow in state.
 * @param state The current ApprovalSettings state object.
 * @returns An object containing the workflow targetAudiences as keys and a boolean indicating whether the resubmission policy setting for that workflow should be disabled
 */
export const shouldResubmissionPoliciesBeDisabled = (state: Types.ApprovalSettingsState): Record<string, boolean> => {
  const response = {};

  forEach(state.workflows, (workflow) => response[workflow.targetAudience] = shouldResubmissionPolicyBeDisabled(workflow));

  return response;
};
