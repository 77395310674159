/* Imports
================================================================================ */
import React, { FC } from 'react';
import { ForgeDialog, ForgeIcon, ForgeIconButton, ForgeInlineMessage, ForgeToolbar } from '@tylertech/forge-react';
import { OverlayProps } from '@tylertech/forge-react/typings/core/createOverlayComponent';

import { CancelActionButton, SaveActionButton } from './ActionButton';
import * as Types from '../types';
import { useApprovalSettingsContext, getTranslateMethod } from '../contexts/ApprovalSettingsContext';





/* Predefined Values
================================================================================ */
const scopedTranslate = getTranslateMethod('save_confirmation');





/* Helper Methods
================================================================================ */





/* Component
================================================================================ */
export const TaskChange: FC<{ task: Types.WorkflowTaskConfigurationData }> = (props) => {
  const { task } = props;
  const message = scopedTranslate('effects.' + [task.scope, task.targetAudience, task.value].join('_'));

  return (<li className='change-item ' dangerouslySetInnerHTML={{ __html: message }} />);
};

export const ChangeList: FC<Types.ChangedSettings> = (props) => {
  const { tasks } = props;
  let taskChanges: JSX.Element[] = [];

  if (tasks.length) taskChanges = tasks.map((task) => <TaskChange task={task} key={task.id} />);

  return (
    <ul className='change-list'>
      {taskChanges}
    </ul>
  );
};

export const SaveConfirmationDialogHeader: FC = () => {
  const {
    actions: { closeApprovalSettingsSaveConfirmation },
    translate
  } = useApprovalSettingsContext();

  return (
    <div className="approval-settings-save-confirmation-dialog-header">
      <ForgeToolbar no-border>
        <h2 className="forge-dialog__title">{scopedTranslate('title')}</h2>
        <ForgeIconButton slot="end">
          <button
            type="button"
            aria-label={translate('actions.cancel')}
            onClick={closeApprovalSettingsSaveConfirmation}
          >
            <ForgeIcon name="close" />
          </button>
        </ForgeIconButton>
      </ForgeToolbar>
    </div>
  );
};

export const SaveConfirmationDialogBody: FC = () => {
  const { state: { changesAffectingPendingRequests } } = useApprovalSettingsContext();

  return (
    <section className="approval-settings-save-confirmation-dialog-body">
      <p>{scopedTranslate('body_start')}</p>
      <ForgeInlineMessage theme='warning'>
        <ChangeList { ...changesAffectingPendingRequests } />
      </ForgeInlineMessage>
      <p>{scopedTranslate('body_confirm')}</p>
    </section>
  );
};

export const SaveConfirmationDialogFooter: FC = () => {
  const {
    actions: { closeApprovalSettingsSaveConfirmation, saveApprovalSettings },
  } = useApprovalSettingsContext();
  const cancelButtonProps = {
    clickHandler: closeApprovalSettingsSaveConfirmation,
    slot: 'end',
  };
  const saveButtonProps = {
    clickHandler: saveApprovalSettings,
    slot: 'end',
    text: scopedTranslate('actions.confirm'),
  };

  return (
    <div className="approval-settings-save-confirmation-dialog-footer">
      <ForgeToolbar no-border>
        <CancelActionButton { ...cancelButtonProps } />
        <SaveActionButton { ...saveButtonProps } />
      </ForgeToolbar>
    </div>
  );
};

const SaveConfirmationDialog: FC = () => {
  const {
    actions: { closeApprovalSettingsSaveConfirmation },
    state
  } = useApprovalSettingsContext();
  const dialogProps: OverlayProps = {
    onDismiss: closeApprovalSettingsSaveConfirmation,
    open: true
  };
  let response = null;

  if (state.saveConfirmationDialogOpen) {
    response = (
      <ForgeDialog { ...dialogProps }>
        <div id='approval-settings-save-confirmation-dialog-inner-wrapper'>
          <SaveConfirmationDialogHeader />
          <SaveConfirmationDialogBody />
          <SaveConfirmationDialogFooter />
        </div>
      </ForgeDialog>
    );
  }

  return response;
};

export default SaveConfirmationDialog;
