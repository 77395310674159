import React from 'react';

import I18n from 'common/i18n/index';

const translationScope = 'shared.approvals.settings';

const Introduction = () => {
  return (
    <div className="introduction">
      <div className="introduction-description">
        {I18n.t('introduction.summary', { scope: translationScope })}
      </div>
      {/* <ul>
        <li>{I18n.t('introduction.summary_bullet_1', { scope: translationScope })}</li>
      </ul> */}
    </div>
  );
};

export default Introduction;
