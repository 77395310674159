/* Imports
================================================================================ */
import React, { FC } from 'react';
import {
  ForgeIcon,
  ForgeIconButton,
  ForgeMenu,
  ForgeTable,
  ForgeTooltip
} from '@tylertech/forge-react';
import { IMenuSelectEventData, IColumnConfiguration } from '@tylertech/forge';
import JsxToHtmlElementService from 'common/tyler_forge/js_utilities/jsxToHtmlElementService/JsxToHtmlElementService';
import * as Types from '../types';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';
import AddApproverCheckbox from './AddApproverCheckbox';


/* Component
================================================================================ */
const ApproversTable: FC<Types.ApproversTableAcceptedProps> = (props: Types.ApproversTableAcceptedProps) => {
  const jsxToHtmlElementService: JsxToHtmlElementService = new JsxToHtmlElementService();
  const {
    state: { isEdpDomain },
    actions: { removeAllApprovalRights, openEditApproverDialog },
    getTranslateMethod
  } = useApprovalSettingsContext();
  const columnHeaderTranslate = getTranslateMethod('sections.approvers.table.column_headers');
  const columnHeaderInfoTranslate = getTranslateMethod('sections.approvers.table.column_info');
  const actionsTranslate = getTranslateMethod('sections.approvers.actions');

  /** Configure columns for approvals table
    * Note that htmlElement required in all templates params because the params are passed in by default
    * (so if you remove the htmlElement param, rowData becomes an html div type)
  */

  // Templates for check marks
  const checkmarkTemplate = (type: 'public' | 'internal', rowIndex: number, hasRight?: boolean) => {
    return jsxToHtmlElementService.wrapJsx(
      (hasRight ? <ForgeIcon name='check'/> : <></>),
      `user_can_approve_${type}_${rowIndex}`
    );
  };

  const checkmarkForPublicApprovalsTemplate = (rowIndex: number, htmlElement: any, rowData: Types.Approvals.Approver) => {
    return checkmarkTemplate('public', rowIndex, rowData.can_review_public);
  };

  const checkmarkForInternalApprovalsTemplate = (rowIndex: number, htmlElement: any, rowData: Types.Approvals.Approver) => {
    return checkmarkTemplate('internal', rowIndex, rowData.can_review_internal);
  };

  // Templates for checkboxes
  const checkboxTemplate = (type: 'public' | 'internal', rowIndex: number, rowData: Types.Approvals.Approver, hasRight = false) => {
    return jsxToHtmlElementService.wrapJsx(
      <div className="checkbox-table-cell">
        <AddApproverCheckbox checked={hasRight} audience={type} email={rowData.email} rowIndex={rowIndex} onCheckboxChanged={handleCheckboxChange} >
        </AddApproverCheckbox>
      </div>,
      `user_can_approve_${type}_${rowIndex}`
    );
  };

  const checkboxForPublicApprovalsTemplate = (rowIndex: number, htmlElement: any, rowData: Types.Approvals.Approver) => {
    return checkboxTemplate('public', rowIndex, rowData, rowData.can_review_public);
  };

  const checkboxForInternalApprovalsTemplate = (rowIndex: number, htmlElement: any, rowData: Types.Approvals.Approver) => {
    return checkboxTemplate('internal', rowIndex, rowData, rowData.can_review_internal);
  };

  const checkboxHeaderForPublicApprovalsTemplate = () => {
    const translation = columnHeaderTranslate('public');
    return jsxToHtmlElementService.wrapJsx(
      <div className='table-header-with-icon'>
        <span className="audience-type" data-testid={'header-checkbox-public'}>{translation}</span>
        <ForgeIcon slot="end" name="info">
          <ForgeTooltip>{columnHeaderInfoTranslate('public')}</ForgeTooltip>
        </ForgeIcon>
      </div>,
      'header-icon-flyout-public'
    );
  };

  const checkboxHeaderForInternalApprovalsTemplate = () => {
    return jsxToHtmlElementService.wrapJsx(
      <div className='table-header-with-icon'>
        <span className="audience-type" data-testid={'header-checkbox-internal'}>{columnHeaderTranslate('internal')}</span>
        <ForgeIcon slot="end" name="info">
          <ForgeTooltip>{columnHeaderInfoTranslate('internal')}</ForgeTooltip>
        </ForgeIcon>
      </div>,
      'header-icon-flyout-internal'
    );
  };


  // Template for creating the Delete and Edit button for each user in the table
  const actionButtonTemplate = (rowIndex: number, htmlElement: any, rowData: Types.Approvals.Approver) => {
    const isDisabled = rowData.role === 'Administrator'; // you can't modify admins here!

    const menuOptions = [
      { value: Types.ApproverActionOption.EDIT, label: 'Edit' },
      { value: Types.ApproverActionOption.DELETE, label: 'Delete' },
    ];

    const onMenuSelect = ({ detail }: CustomEvent<IMenuSelectEventData>) => {
      switch (detail.value) {
        case Types.ApproverActionOption.DELETE: {
          handleRemoveReviewerRights(rowData);
        } break;
        case Types.ApproverActionOption.EDIT: {
          handleEditApprover(rowData);
        }
      }
    };

    const responseDiv = (
      <div>
        <ForgeMenu popupClasses='user-action-options' on-forge-menu-select={onMenuSelect} options={menuOptions}>
          <ForgeIconButton>
            {isDisabled && <ForgeTooltip>{actionsTranslate('no_admin_edits')}</ForgeTooltip>}
            <button disabled={isDisabled} type="button" aria-label="actions" className="user-action-button" id={`${rowIndex}-actions-button`}>
              <ForgeIcon name="more_vert"></ForgeIcon>
            </button>
          </ForgeIconButton>
        </ForgeMenu>
      </div>
    );

    const response = jsxToHtmlElementService.wrapJsx(responseDiv, `use_actions_${rowIndex}`);

    return response;
  };

  // Template for creating the remove pending approver button in the add approvers modal
  const cancelButtonTemplate = (rowIndex: number, htmlElement: any, rowData: Types.Approvals.Approver) => {
    const responseDiv = (
      <div>
        <ForgeIconButton>
          <button type="button" aria-label="close" className="user-action-button" id={`${rowIndex}-cancel-button`}
              onClick={() => handleRemoveFromUsersToAdd(rowData)}>
            <ForgeIcon name="close"></ForgeIcon>
          </button>
        </ForgeIconButton>
      </div>
    );

    const response = jsxToHtmlElementService.wrapJsx(responseDiv, `cancel_${rowIndex}`);

    return response;
  };

  /** Create different column configurations for different locations of this approvers table*/
  const buildColumnConfig = (isEdp: boolean): IColumnConfiguration[] => {
    // all tables display username and email
    let columnConfig: IColumnConfiguration[] = [];
    columnConfig.push(
      { property: 'userName', header: columnHeaderTranslate('user_name') },
      { property: 'email', header: columnHeaderTranslate('email') }
    );

    switch (props.tableType) {
      case Types.ApproversTableType.SETTINGS_PAGE:
        columnConfig = columnConfig.concat(buildSettingsPageColumnConfig(isEdp));
        break;
      case Types.ApproversTableType.SETTINGS_MODAL_ADD:
        columnConfig = columnConfig.concat(buildAddApproversModalColumnConfig(isEdp));
        break;
      case Types.ApproversTableType.SETTINGS_MODAL_EDIT:
        columnConfig = columnConfig.concat(buildEditApproverModalColumnConfig(isEdp));
        break;
      default:
        columnConfig = columnConfig.concat(buildSettingsPageColumnConfig(isEdp));
        break;
    }

    return columnConfig;
  };

  const buildSettingsPageColumnConfig = (isEdp: boolean): IColumnConfiguration[] => {
    const colConfig: IColumnConfiguration[] = [];
    colConfig.push(
      { property: 'role', header: columnHeaderTranslate('role') }
    );

    if (isEdp) {
      colConfig.push(
        { property: 'public', header: columnHeaderTranslate('public'), template: checkmarkForPublicApprovalsTemplate },
        { property: 'internal', header: columnHeaderTranslate('internal'), template: checkmarkForInternalApprovalsTemplate },
        { property: 'actions', header: columnHeaderTranslate('actions'), template: actionButtonTemplate }
      );
    }

    return colConfig;
  };

  const buildAddApproversModalColumnConfig = (isEdp: boolean): IColumnConfiguration[] => {
    const colConfig: IColumnConfiguration[] = [];

    if (isEdp) {
      colConfig.push(
        { property: 'public', headerTemplate: checkboxHeaderForPublicApprovalsTemplate, template: checkboxForPublicApprovalsTemplate },
        { property: 'internal', headerTemplate: checkboxHeaderForInternalApprovalsTemplate, template: checkboxForInternalApprovalsTemplate }
      );
    }

    colConfig.push({ property: 'cancel', header: '', template: cancelButtonTemplate });

    return colConfig;
  };

  const buildEditApproverModalColumnConfig = (isEdp: boolean): IColumnConfiguration[] => {
    const colConfig: IColumnConfiguration[] = [];

    if (isEdp) {
      colConfig.push(
        { property: 'public', headerTemplate: checkboxHeaderForPublicApprovalsTemplate, template: checkboxForPublicApprovalsTemplate },
        { property: 'internal', headerTemplate: checkboxHeaderForInternalApprovalsTemplate, template: checkboxForInternalApprovalsTemplate }
      );
    }

    return colConfig;
  };

  // Click handlers
  const handleCheckboxChange = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onCheckboxChanged) {
      props.onCheckboxChanged(e);
    }
  };

  const handleRemoveFromUsersToAdd = (row: Types.Approvals.Approver) => {
      if (props.onRemoveUser) {
        props.onRemoveUser(row);
      }
    };

  const handleRemoveReviewerRights = (approver: Types.Approvals.Approver) => {
    removeAllApprovalRights(approver);
  };

  const handleEditApprover = (approver: Types.Approvals.Approver) => {
    openEditApproverDialog(approver);
  };

  const columnConfig = buildColumnConfig(isEdpDomain);

  return (
    <ForgeTable
      className="parameters-table"
      columnConfigurations={columnConfig}
      data={props.users}
    />
  );
};

export default ApproversTable;
