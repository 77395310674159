import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import I18n from 'common/i18n/index';
import * as constants from 'common/components/AssetBrowser/lib/constants';

import * as actions from 'frontend/public/javascripts/approvals/components/Settings/actions';
import { APPROVALS } from 'common/core/approvals_enums';

const { APPROVED, PENDING, REJECTED } = APPROVALS.STATUS;
const { AUTHORITY_COMMUNITY, AUTHORITY_OFFICIAL } = constants;

const translationScope = 'shared.approvals.settings';

const ApprovalConfigOption = (props) => {
  const {
    editing,
    onClick,
    provenance,
    selected,
    value
  } = props;

  const id = `approval-configuration-${provenance}-${value}`;
  const classes = classNames('config-option', { selected, disabled: !editing });

  const radioButton = (
    <input
      type="radio"
      id={id}
      name={`approval-configuration-${provenance}`}
      checked={selected} />
  );

  return (
    <li className={classes} onClick={onClick}>
      {radioButton}
      <label className="radio_label" htmlFor={id}>
        {I18n.t(`options.${value}`, { scope: translationScope })}
      </label>
    </li>
  );
};

ApprovalConfigOption.propTypes = {
  onClick: PropTypes.func,
  provenance: PropTypes.oneOf([AUTHORITY_COMMUNITY, AUTHORITY_OFFICIAL]).isRequired,
  value: PropTypes.oneOf([APPROVED, PENDING, REJECTED]).isRequired
};

const mapStateToProps = (state, ownProps) => ({
  selected: state[ownProps.provenance].presetState === ownProps.value,
  editing: state.editing
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => dispatch(actions.selectProvenanceConfig(ownProps.provenance, ownProps.value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalConfigOption);
