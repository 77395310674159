import _ from 'lodash';
import I18n from 'common/i18n';
import InteractiveUser from 'common/types/users/interactiveUser';
import { Approver, Role } from 'common/types/approvals';

/**
 * The response from /api/approvals returns a list of approvers for each workflow, so a user can be on multiple lists.
 * But the approvers table is only one list of users. This function receives both lists (public and internal) and
 * merges them into one list, using the attributes can_review_public and can_review_internal to record which lists
 * the user was in.
 *
 * @param publicApprovers List of users that have right to approve assets destined for public audience
 * @param internalApprovers List of users that have right to approve assets destined for internal audience
 */
export const mergeUsers = (publicApprovers?: InteractiveUser[], internalApprovers?: InteractiveUser[], roles?: Role[]) : Approver[] => {
  const approvers: Approver[] = [];
    if (publicApprovers) {
          // Gather all public approvers that also have an internal approver, and add them to result list
      _.forEach(publicApprovers, function(thisApprover) {
        // create approver to add to array
        const publicApprover: Approver = {
          userName: thisApprover.displayName,
          email: thisApprover.email,
          role: getHumanReadableRole(thisApprover.roleName || '', roles), // will be updated in EN-60916 // roleName is safe to use here because that's what BE returns
          can_review_public: true,
          uid: thisApprover.id
        };

        // if the same user is in internal approvers list, set can_review_internal to true for current user
        if (internalApprovers) {
          const user = _.find(internalApprovers, function(a) {return a.email === publicApprover.email;});
          if (user) {
            publicApprover.can_review_internal = true;
          }
        }

        approvers.push(publicApprover);
      });
    }

    // go through internal approvers and add any that aren't already in the result list
    // (this will ensure that approvers that can only approve internally are added too)
    if (internalApprovers) {
      _.forEach(internalApprovers, function(thisApprover) {
        if (!approvers.find((a) => a.email === thisApprover.email)) {
          const internalApprover: Approver = {
            userName: thisApprover.displayName,
            email: thisApprover.email,
            role: getHumanReadableRole(thisApprover.roleName || '', roles), // roleName is safe to use here because that's what BE returns
            can_review_internal: true,
            can_review_public: false,
            uid: thisApprover.id
          };
          approvers.push(internalApprover);
        }
      });
    }

    return approvers;
  };

/** Function to pass in a role in database format (like internal_user) and return a string
 * with the human-readable role (like Internal User). ~~Inspired by~~ code in adminUsersV2
 * utils.js.
 *
 * If the role is a default role, we'll grab the translation for that role since we already
 * have it. Otherwise we'll have to print out the role of the user
 * (which has been stored as a human-readable name when the role was created). If the custom
 * role name matches a default role name, we add "Custom" onto the end of the sname.
 *
 * Meant to be used with pages that have roles translations available. To check whether you
 * need to add that translation, use window.translations on your page and see if roles is a
 * top-level translations object available.
 */
export const getHumanReadableRole = (roleKey: string, domainRoles?: Role[]): string => {
  const thisRole = _.find(domainRoles, function (role) {
    return role.name == roleKey;
  });

  if (thisRole) {
    if (thisRole.isDefault) {
      return I18n.t(`roles.default_roles.${thisRole.name}.name`);
    } else {
      const otherRole = _.find(domainRoles, function (role) {
        role.isDefault && role.name.toLowerCase() === thisRole.name.toLowerCase();
      });
      if (otherRole) return `${thisRole.name} (${I18n.t('users.roles.custom')})`;
      else return thisRole.name;
    }
  } else {
    return '';
  }
};


/**
 * Used to determine whether the save button in the Add Approvers modal should be enabled
 * or disabled. If any row has both boxes unchecked, the save button is disabled.
 */
export const anyApproverHasNoWorkflowSelected = (approvers: Approver[]): boolean => {
  if (approvers.length === 0) {
    return true;
  }

  let saveButtonIsDisabled = false;
  for (let i = 0; i < approvers.length; i++) {
    if ((approvers[i].can_review_internal === false || approvers[i].can_review_internal === undefined) &&
        (approvers[i].can_review_public == false || approvers[i].can_review_public === undefined)) {
      saveButtonIsDisabled = true;
      break;
    }
  }

  return saveButtonIsDisabled;
};
