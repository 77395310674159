import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n/index';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import { APPROVALS } from 'common/core/approvals_enums';

import ApprovalConfigOption from './ApprovalConfigOption';
import ConfigHeader from './ConfigHeader';
import ConfigWarning from './ConfigWarning';
import { AFFECTED_SCOPE } from '../constants';

const translationScope = 'shared.approvals.settings';

const CommunityAssetConfig = (props) => {
  return (
    <div className="community_assets">
      <ConfigHeader section={constants.AUTHORITY_COMMUNITY} />

      <div className="approval-settings-section-body">
        <div className="approval-settings-section-description">
          {I18n.t('community.subtitle_2', { scope: translationScope })}
        </div>

        <div className="approval-settings-section-configuration-options">
          <ul>
            {props.options.map((opt) =>
              <ApprovalConfigOption
                key={opt}
                provenance={constants.AUTHORITY_COMMUNITY}
                value={opt}
              />
            )}
          </ul>
        </div>
      </div>

      <ConfigWarning
        hidden={!props.showWarning}
        affectedScope={AFFECTED_SCOPE.ALL_REQUESTS}
        outcome={props.warningOutcome}
      />

    </div>
  );
};

CommunityAssetConfig.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOf(Object.values(APPROVALS.STATUS)))
};

const mapStateToProps = state => ({
  showWarning: changingFromPending(state),
  warningOutcome: state.community.presetState || APPROVALS.STATUS.APPROVED
});

const changingFromPending = state => {
  const { presetState: unsaved, currentPresetState: current } = state.community;
  return current === APPROVALS.STATUS.PENDING && unsaved !== current;
};

export default connect(mapStateToProps)(CommunityAssetConfig);
