import * as actions from './actions';
import { getProvenanceTask } from 'common/core/approvals';
import { APPROVALS } from 'common/core/approvals_enums';

// Shape of the store
const initialState = {
  community: {
    taskId: null,
    presetState: '',
    currentPresetState: ''
  },
  official: {
    taskId: null,
    presetState: '',
    currentPresetState: ''
  },
  approvalWorkflow: {
    id: null,
    approvedResubmissionPolicy: '',
    currentApprovedResubmissionPolicy: ''
  },
  approvers: [],
  editing: false,
  cancelling: false,
  saving: false,
  assetUpdateConfigEnabled: false,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_SETTINGS_SUCCESS: {
      const updatedState = { ...state, ...mapResponseToState(action.response) };
      return applyAssetUpdateConfigEnabled(updatedState);
    }
    case actions.FETCH_SETTINGS_FAILURE: {
      return { ...state, error: action.response };
    }
    case actions.SELECT_PROVENANCE_CONFIG: {
      const updatedState = {
        ...state,
        [action.provenance]: {
          ...state[action.provenance],
          presetState: action.value
        }
      };

      return applyAssetUpdateConfigEnabled(updatedState);
    }
    case actions.SELECT_RESUBMISSION_CONFIG: {
      return {
        ...state,
        approvalWorkflow: {
          ...state.approvalWorkflow,
          approvedResubmissionPolicy: action.value
        }
      };
    }
    case actions.EDITING_ENABLED: {
      return { ...state, editing: true };
    }
    case actions.EDITING_CANCELLED: {
      const updatedState = {
        ...state,
        editing: false,
        community: {
          ...state.community,
          presetState: state.community.currentPresetState
        },
        official: {
          ...state.official,
          presetState: state.official.currentPresetState
        },
        approvalWorkflow: {
          ...state.approvalWorkflow,
          approvedResubmissionPolicy: state.approvalWorkflow.currentApprovedResubmissionPolicy
        },
      };

      return applyAssetUpdateConfigEnabled(updatedState);
    }
    case actions.SAVING_STARTED: {
      return { ...state,  saving: true };
    }
    case actions.SAVING_SUCCESSFUL: {
      const updatedState = {
        ...state,
        editing: false,
        community: {
          ...state.community,
          currentPresetState: state.community.presetState
        },
        official: {
          ...state.official,
          currentPresetState: state.official.presetState
        },
        approvalWorkflow: {
          ...state.approvalWorkflow,
          currentApprovedResubmissionPolicy: state.approvalWorkflow.approvedResubmissionPolicy
        },
      };

      return applyAssetUpdateConfigEnabled(updatedState);
    }
    case actions.SAVING_FINISHED: {
      return { ...state,  saving: false };
    }
    default:
      return state;
  }
};

/**
 * Helpers
 **/

// GET /api/approvals for example of `approvalsApiResponse`
const mapResponseToState = approvalsApiResponse => ({
  community: {
    ...getProvenanceTask(approvalsApiResponse, 'community'),
    currentPresetState: getProvenanceTask(approvalsApiResponse, 'community').presetState
  },
  official: {
    ...getProvenanceTask(approvalsApiResponse, 'official'),
    currentPresetState: getProvenanceTask(approvalsApiResponse, 'official').presetState
  },
  approvalWorkflow: {
    id: approvalsApiResponse[0].id,
    approvedResubmissionPolicy: approvalsApiResponse[0].approvedResubmissionPolicy,
    currentApprovedResubmissionPolicy: approvalsApiResponse[0].approvedResubmissionPolicy
  },
  approvers: approvalsApiResponse[0].approvers
});

// Takes a *post-action* state, and sets assetUpdateConfigEnabled
const applyAssetUpdateConfigEnabled = (state) => {
  const presetStates = [state.official, state.community].map(s => s.presetState);
  return {
    ...state,
    assetUpdateConfigEnabled: presetStates.includes(APPROVALS.STATUS.PENDING)
  };
};
