/* Imports
================================================================================ */
import React, { FC } from 'react';
import { defaults } from 'lodash';
import { ForgeButton } from '@tylertech/forge-react';

import * as Types from '../types';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */






/* Components
================================================================================ */
export const CancelActionButton: FC<Types.CancelActionButtonProps> = (props) => {
  const { translate } = useApprovalSettingsContext();
  const cancelButtonProps: Types.ActionButtonProps = defaults({}, props, {
    text: translate('actions.cancel'),
    type: 'outlined',
    variant: 'default',
  });

  return (<ActionButton { ...cancelButtonProps } />);
};

export const SaveActionButton: FC<Types.SaveActionButtonProps> = (props) => {
  const { translate } = useApprovalSettingsContext();
  const saveButtonProps: Types.ActionButtonProps = defaults({}, props, {
    text: translate('actions.save'),
    type: 'raised',
    variant: 'primary',
  });

  return (<ActionButton { ...saveButtonProps } />);
};

const ActionButton: FC<Types.ActionButtonProps> = (props) => {
  const {
    clickHandler,
    disabled,
    slot,
    text,
    type,
    variant
  } = props;

  return (
    <ForgeButton variant={variant} type={type} slot={slot || 'start'}>
      <button type='button' disabled={!!disabled} onClick={clickHandler}>{text}</button>
    </ForgeButton>
  );
};

export default ActionButton;
