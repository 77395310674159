/* Imports
================================================================================ */
import React, { FC } from 'react';
import { compact, defaults, sortBy } from 'lodash';

import {
  ApprovalState,
  ResubmissionSettings
} from 'common/core/approvals_enums';

import * as Selectors from '../selectors';
import * as Types from '../types';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';
import ConfigurationOption from './ConfigurationOption';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */
export const createScopeString = (...args: string[]): string => {
  return compact(args).join('-');
};

export const getRadioConfigurationOptionList = (
  sectionScope: Types.SectionScope,
  state: Types.ApprovalSettingsState,
  translate: Types.TranslateMethod
): Partial<Types.ConfigurationOptionProps>[] => {
  const configurationOptionList = [
    {
      inputs: [
        {
          label: translate('options.auto_approved'),
          value: ApprovalState.APPROVED,
        },
        {
          label: translate('options.pending_manual'),
          value: ApprovalState.PENDING,
        },
      ],
      type: 'radio',
      disabled: false
    }
  ];

  // Add "Auto-Reject" option for community assets on ODP domains only
  if (!state.isEdpDomain && sectionScope === 'community') {
    configurationOptionList[0].inputs.push({
      label: translate('options.auto_rejected'),
      value: ApprovalState.REJECTED,
    });
  }

  return configurationOptionList;
};

export const renderConfigurationOptions = (props: Types.ConfigurationItemRenderConfigurationOptionsProps) => {
  const {
    state,
    sectionScope,
    itemScope,
    targetAudience,
    scopeString,
    configurationOptionList,
  } = props;
  const value = Selectors.getCurrentSavedOrDefaultValue(state, {
    sectionScope,
    itemScope,
    targetAudience,
  });

  return configurationOptionList.map((configurationOptionProps) => {
    const configOptionProps = defaults({
      targetAudience,
      sectionScope,
      itemScope,
      disabled: itemScope === 'resubmission_policy' ? state.workflows[targetAudience].isApprovedResubmissionPolicyForced : false,
      name: scopeString,
      value,
    }, configurationOptionProps) as Types.ConfigurationOptionProps;

    return (<ConfigurationOption { ...configOptionProps } key={configOptionProps.name} />);
  });
};

export const renderWorkflows = (props: Types.ConfigurationItemRenderWorkflowProps) => {
  const {
    sectionScope,
    itemScope,
    state,
    configurationOptionList,
  } = props;
  const sortedWorkflows: Types.WorkflowConfigurationData[] = sortBy(Object.values(state.workflows), 'order');

  return sortedWorkflows.map((workflow) => {
    let response = null;

    if (workflow.enabled) {
      const scopeString = createScopeString(sectionScope, itemScope, workflow.targetAudience);
      const configurationOptions = renderConfigurationOptions({
        state,
        sectionScope,
        itemScope,
        targetAudience: workflow.targetAudience,
        scopeString,
        configurationOptionList
      });

      response = (
        <ConfigurationItemWorkflow header={workflow.targetAudience} key={scopeString}>
          {configurationOptions}
        </ConfigurationItemWorkflow>
      );
    }

    return response;
  });
};





/* Components
================================================================================ */
export const ApprovalsProcessConfigurationItem: FC <Types.ConfigurationItemProps>  = (props) => {
  const { state, translate } = useApprovalSettingsContext();
  const { sectionScope } = props;
  const children = renderWorkflows({
    configurationOptionList: getRadioConfigurationOptionList(sectionScope, state, translate),
    itemScope: 'approvals_process',
    sectionScope,
    state,
  });


  return (<ConfigurationItem {...props}>{children}</ConfigurationItem>);
};

export const ResubmissionPolicyProcessConfigurationItem: FC<Types.ConfigurationItemProps> = (props) => {
  const { state } = useApprovalSettingsContext();
  const { sectionScope } = props;
  const children = renderWorkflows({
    configurationOptionList: [
      {
        inputs: [
          {
            label: '',
            selectedValue: ResubmissionSettings.RESUBMIT,
            unselectedValue: ResubmissionSettings.MAINTAIN_STATE,
          },
        ],
        type: 'switch',
      },
    ],
    itemScope: 'resubmission_policy',
    sectionScope,
    state,
  });


  return (<ConfigurationItem {...props}>{children}</ConfigurationItem>);
};

export const ConfigurationItemWorkflow: FC<Types.ConfigurationItemWorkflowProps> = (props) => {
  const {
    header,
    children,
  } = props;

  return (
    <div className="approval-settings-configuration-item-workflow">
      <h6 className="approval-settings-configuration-item-workflow-header">
        {header}
      </h6>

      {children}
    </div>
  );
};

const ConfigurationItem: FC<Types.ConfigurationItemProps> = (props) => {
  const {
    title,
    children,
  } = props;

  return (
    <div className="approval-settings-configuration-item">
      <div className="approval-settings-configuration-item-title">
        {title}
      </div>

      {children}
    </div>
  );
};

export default ConfigurationItem;
