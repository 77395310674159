/* Imports
================================================================================ */
import React, { useEffect, FC } from 'react';

import { ForgeCard, ForgeIcon } from '@tylertech/forge-react';

import * as Types from './types';
import { useApprovalSettingsContext } from './contexts/ApprovalSettingsContext';
import Introduction from './components/Introduction';
import Sections from './components/Sections';
import ApproversSection from './components/ApproversSection';
import SaveConfirmationDialog from './components/SaveConfirmationDialog';

import './approval-settings.scss';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */





/* Components
================================================================================ */
export const LearnMore: FC<Types.LearnMoreProps> = (props) => {
  const { linkText, url, } = props;

  return (
    <div className='learn_more'>
      <ForgeIcon name='info'/>
      <a href={url}>
        {linkText}
      </a>
    </div>
  );
};

const ApprovalSettings: FC = () => {
  const {
    actions: { fetchSettings },
    translate
  } = useApprovalSettingsContext();
  const learnMoreProps = {
    linkText: translate('learn_more'),
    url: 'https://support.socrata.com/hc/en-us/articles/360021671013-Configuring-Approvals',
  };

  // Load the initial settings, roles, and approvers
  useEffect(() => fetchSettings(), [fetchSettings]);

  return (
    <div className="approval-settings-v2">
      <div className="approval-settings-container forge-typography--body2">
        <ForgeCard outlined={false}>

          {/* Introduction
            ---------------------------------------------------------- */}
          <Introduction />



          {/* Sections
            ---------------------------------------------------------- */}
          <Sections />
        </ForgeCard>

        {/* Approvers Section
          ------------------------------------------------------------ */}
        <ApproversSection />


        {/* Learn More
          ------------------------------------------------------------ */}
        <LearnMore { ...learnMoreProps } />


        {/* Save Confirmation Dialog
          ------------------------------------------------------------ */}
        <SaveConfirmationDialog />

      </div>
    </div>
  );
};

export default ApprovalSettings;
