/* Imports
================================================================================ */
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import airbrake from 'common/airbrake';
import FeatureFlags from 'common/feature_flags';
import { dateLocalize } from 'common/locale';

import Settings from './components/Settings';
import SettingsV2 from './components/SettingsV2';
import settingsReducer from './components/Settings/reducer';
import createApprovalSettingsStore from './components/SettingsV2/createStore';
import { ApprovalSettingsProvider } from './components/SettingsV2/contexts/ApprovalSettingsContext';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */
function renderSettings () {
    return (
      <Provider store={createApprovalSettingsStore()}>
        <ApprovalSettingsProvider>
          <SettingsV2 />
        </ApprovalSettingsProvider>
      </Provider>
    );
}





/* Setup
================================================================================ */
airbrake.init(_.get(window, 'serverConfig.airbrakeProjectId'), _.get(window, 'serverConfig.airbrakeKey'));





/* Rendering
================================================================================ */
ReactDOM.render(
  renderSettings(),
  document.querySelector('.approvals_settings')
);

Array.from(document.querySelectorAll('.dateLocalize')).forEach(dateLocalize);
