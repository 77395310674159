/* Imports
================================================================================ */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducer, { initialState } from './reducer';
import sagas from './sagas';






/* Predefined Values
================================================================================ */
// This is used to control actions during tests
const devToolsConfig = {
  actionsDenylist: [
    // Actions to disallow during tests should be listed here
  ],
  name: 'Approval Settings V2'
};





/* Helper Methods
================================================================================ */
const getComposeFn = () => (
  (
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfig)
  ) ||
  compose
);





/* Create Store
================================================================================ */
const createApprovalSettingsStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const composeFn = getComposeFn();
  const store = createStore(reducer, initialState, composeFn(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(sagas);

  return store;
};

export default createApprovalSettingsStore;
