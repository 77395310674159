/* Imports
================================================================================ */
import React, { FC, useState } from 'react';
import {
  ForgeButton,
  ForgeIcon,
  ForgeIconButton,
  ForgeToolbar,
  ForgeTooltip,
} from '@tylertech/forge-react';
import * as Helpers from '../helpers';
import * as Types from '../types';
import {cloneDeep} from 'lodash';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';
import ApproversTable from './ApproversTable';
import { Approver } from 'common/types/approvals';


/* Component
================================================================================ */
const EditApproversModal: FC<Types.EditApproversModalAcceptedProps> = (props: Types.EditApproversModalAcceptedProps) => {
  const {
    state: { users, approverToEdit },
    actions: { closeEditApproverDialog, editApprover },
    getTranslateMethod
  } = useApprovalSettingsContext();
  const translate = getTranslateMethod('sections.approvers.actions');
  const translateTopLevelActions = getTranslateMethod('actions');
  const addApproverTranslate = getTranslateMethod('sections.approvers.add_approver');

  // Store original values of this approver
  const [originalApprover] = useState<Approver|undefined>(cloneDeep(approverToEdit));
  const [editedApprover, setEditedApprover] = useState<Approver|undefined>(cloneDeep(approverToEdit));

  // evaluate save button on page load
  const [saveIsDisabled, setSaveIsDisabled] = useState<boolean>(false);

  // Approvers table needs an array, so we're creating that array here
  let approverForEditingArray: Approver[] = [];
  if (approverToEdit != undefined) {
    approverForEditingArray = [approverToEdit];
  }

  // When a checkbox is checked or unchecked, we must re-evaluate whether the save button is enabled or disabled.
  // We also keep track of the edits we make here
  const onCheckboxChanged = (e: any) => {
    const audience = e.currentTarget.getAttribute('data-audience');
    const chkd = e.target.checked;

    if (approverToEdit) {
      const buildApproverTypeFromUser: Approver = approverToEdit;
      if (audience === 'public') {
        buildApproverTypeFromUser.can_review_public = chkd;
      } else if (audience === 'internal') {
        buildApproverTypeFromUser.can_review_internal = chkd;
      }

      setEditedApprover(buildApproverTypeFromUser);
      // re-evaluate save button
      if (Helpers.anyApproverHasNoWorkflowSelected([buildApproverTypeFromUser])) {
        setSaveIsDisabled(true);
      } else {
          setSaveIsDisabled(false);
      }
    }
  };

  // Kick of work to edit the approver and also update approvers table
  const onSaveEditedApprover = () => {
    if (editedApprover) {
      editApprover(originalApprover, editedApprover);
      const foundIndex = users.findIndex((app) => (app.email === editedApprover.email));
      users[foundIndex].can_review_internal = editedApprover.can_review_internal;
      users[foundIndex].can_review_public = editedApprover.can_review_public;
      onCloseDialog();
    }
  };

  const onCloseDialog = () => {
    closeEditApproverDialog();
  };

  return (
    <div>
      <ForgeToolbar no-border={true}>
        <span className="forge-typography--headline5 title-override" slot="start">{translate('edit_approver')}</span>
        <ForgeIconButton data-testid="edit-approvers-modal-close-button" slot="end">
            <button data-testid='close-edit-approvers-modal-button' onClick={onCloseDialog} type="button">
            <ForgeIcon name="close" />
            </button>
        </ForgeIconButton>
      </ForgeToolbar>
        <div className="forge-dialog__body edit-dialog-body-override">
          <div className="forge-typography--body1">{translate('update_approver')}</div>
        </div>
        <div className="table-div">
          <ApproversTable tableType={Types.ApproversTableType.SETTINGS_MODAL_EDIT} users={approverForEditingArray} onCheckboxChanged={onCheckboxChanged} />
        </div>
      <footer className="forge-dialog__footer dialog-footer-override">
        <ForgeButton type="outlined" className='cancel-button'>
            <button data-testid="edit-approvers-modal-cancel-button" type="button" onClick={onCloseDialog}>{translateTopLevelActions('cancel')}</button>
        </ForgeButton>
        <ForgeButton type="raised">
            {saveIsDisabled && <ForgeTooltip>{addApproverTranslate('save_requirements')}</ForgeTooltip>}
            <button data-testid="edit-approvers-modal-save-button" type="button" onClick={onSaveEditedApprover} forge-dialog-focus="true"
               disabled={saveIsDisabled}>{translateTopLevelActions('save')}</button>
        </ForgeButton>
      </footer>
    </div>
  );
};

export default EditApproversModal;
