/* Imports
================================================================================ */
import React, { FC } from 'react';

import * as Types from '../types';
import OfficialAssetsSection from './sections/OfficialAssetsSection';
import CommunityAssetsSection from './sections/CommunityAssetsSection';
import AssetUpdatesSection from './sections/AssetUpdatesSection';





/* Predefined Values
================================================================================ */






/* Helper Methods
================================================================================ */





/* Component
================================================================================ */
const Sections: FC = () => {
  return (
    <div className="approval-settings-section-container">
      <OfficialAssetsSection />
      <CommunityAssetsSection />
      <AssetUpdatesSection />
    </div>
  );
};

export default Sections;
