import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/Button';
import I18n from 'common/i18n/index';
import { setApprovedResubmissionPolicy, setPresetState } from 'common/core/approvals';
import {
  showErrorToastNow,
  showInfoToastNow,
  showSuccessToastNow
} from 'common/components/ToastNotification/Toastmaster';

import * as actions from 'frontend/public/javascripts/approvals/components/Settings/actions';

const translationScope = 'shared.approvals.settings';

const Actions = (props) => {
  const {
    cancelling,
    editing,
    saving,
    editingEnabled,
    editingCancelled
  } = props;

  const onClickSave = (saveProps) => () => {
    const { savingStarted, savingSuccessful, savingFinished } = saveProps;
    savingStarted();
    return updateSettings(saveProps).then(() => {
      savingSuccessful();
      showSuccessToastNow(I18n.t('toast.success', { scope: translationScope }));
    }).catch((err) => {
      showErrorToastNow(I18n.t('toast.error', { scope: translationScope }));
      console.error(err);
    }).finally(() => {
      savingFinished();
    });
  };

  const editingToast = () => showInfoToastNow(I18n.t('toast.editing_cancelled', { scope: translationScope }));

  return (
    <div className="actions">
      <div className={`action_buttons${editing ? ' disabled' : ''}`}>
        <Button variant='primary' onClick={editingEnabled}>
          {I18n.t('actions.edit', { scope: translationScope })}
        </Button>
      </div>

      <div className={`action_buttons${(editing || cancelling) ? '' : ' disabled'}`}>
        <Button variant='primary'
                busy={saving}
                disabled={cancelling}
                onClick={onClickSave(props)}>
          {I18n.t('actions.save', { scope: translationScope })}
        </Button>
        <Button variant='default'
                busy={cancelling}
                disabled={saving}
                onClick={() => editingCancelled() && editingToast()}>
          {I18n.t('actions.cancel', { scope: translationScope })}
        </Button>
      </div>
    </div>
  );
};

// Helpers
const updateSettings = (props) => {
  const {
    communityId,
    communityPresetState,
    officialId,
    officialPresetState,
    workflowId,
    policy
  } = props;

  const apiCalls = [
    setPresetState(communityId, communityPresetState),
    setPresetState(officialId, officialPresetState),
    setApprovedResubmissionPolicy(workflowId, policy)
  ];

  return Promise.all(apiCalls);
};

const mapStateToProps = (state) => ({
  editing: state.editing,
  cancelling: state.cancelling,
  saving: state.saving,
  communityId: state.community.taskId,
  communityPresetState: state.community.presetState,
  officialId: state.official.taskId,
  officialPresetState: state.official.presetState,
  workflowId: state.approvalWorkflow.id,
  policy: state.approvalWorkflow.approvedResubmissionPolicy
});

const mapDispatchToProps = (dispatch) => ({
  editingEnabled: () => dispatch(actions.editingEnabled),
  editingCancelled: () => dispatch(actions.editingCancelled),
  savingStarted: () => dispatch(actions.savingStarted),
  savingSuccessful: () => dispatch(actions.savingSuccessful),
  savingFinished: () => dispatch(actions.savingFinished)
});


export default connect(mapStateToProps, mapDispatchToProps)(Actions);
