/* Imports
================================================================================ */
import { AnyAction } from 'redux';
import { set, forEach, filter } from 'lodash';

import { showForgeErrorToastNow, showForgeSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';

import { baseTranslateMethod } from './contexts/ApprovalSettingsContext';
import * as Selectors from './selectors';
import * as Types from './types';
import { mergeUsers } from './helpers';



/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */
/**
 * Force or reset the current resubmission policy value unless all of the following are true:
 *  - A current value exists
 *  - The `override` param is not equal to `true`
 *  - The conditions for disabling the resubmission policy are not met
 * NOTE - If you are calling both this and populateCurrentTaskSettings, call populateCurrentTaskSettings first to ensure desired results
 * @param state An ApprovalSettingsState object
 * @param override (Optional, Default: false) A flag indicating whether the current value should be re-populated even if it is set.
 * @returns An ApprovalSettingsState object
 */
export const populateCurrentResubmissionPolicySettings = (state: Types.ApprovalSettingsState, override = false): Types.ApprovalSettingsState => {
  forEach(state.workflows, (workflow) => {
    if (!workflow.approvedResubmissionPolicy || override) {
      // If no current value is set or the override flag is true, force or reset the value
      forceOrResetResubmissionPolicyValue(workflow);
    } else if (workflow.approvedResubmissionPolicy && Selectors.shouldResubmissionPolicyBeDisabled(workflow)) {
      // If there is a current value and the resubmission policy should be disabled, force the value
      forceResubmissionPolicyValue(workflow);
    }
  });

  return state;
};

/**
 * Set the current resubmission policy value based on the saved or default value if the current value is undefined or the `override` param is true
 * @param state An ApprovalSettingsState object
 * @param override (Optional, Default: false) A flag indicating whether the current value should be re-populated even if it is set.
 * @returns An ApprovalSettingsState object
 */
export const populateCurrentTaskSettings = (state: Types.ApprovalSettingsState, override = false): Types.ApprovalSettingsState => {
  const tasks = Selectors.collectTasks(state);

  forEach(tasks, (task) => {
    if (!task.value || override) task.value = (task.savedValue || task.defaultValue);
  });

  return state;
};

/**
 * If necessary set each current resubmission policy value and task value.
 *  - Current task values will be based on the saved or default value.
 *  - See `populateCurrentResubmissionPolicySettings` for the conditions under which current resubmission policy values will be set.
 * @param state An ApprovalSettingsState object
 * @returns An ApprovalSettingsState object
 */
export const populateCurrentSettings = (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
  populateCurrentTaskSettings(state);
  populateCurrentResubmissionPolicySettings(state);

  return state;
};

/**
 * Set the current resubmission policy value or task value based on the saved or default value
 * @param state An ApprovalSettingsState object
 * @returns An ApprovalSettingsState object
 */
export const resetCurrentSettings = (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
  populateCurrentTaskSettings(state, true);
  populateCurrentResubmissionPolicySettings(state, true);

  return state;
};

/**
 * Set attributes, resubmission policy values and task values based on settings from the API response.
 * @param state An ApprovalSettingsState object
 * @returns An ApprovalSettingsState object
 */
export const populateApiSettings = (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
  forEach(state.apiWorkflows, (apiWorkflow) => {
    const workflow = state.workflows[apiWorkflow.targetAudience];

    // Set task attributes and settings
    forEach(apiWorkflow.steps[0].tasks, (apiTask) => {
      const task = workflow.tasks[apiTask.scope];

      task.id = apiTask.id;
      task.value = apiTask.presetState;
      task.savedValue = apiTask.presetState;
    });

    // Set workflow attributes & resubmission policy
    workflow.id = apiWorkflow.id;
    workflow.approvedResubmissionPolicy = apiWorkflow.approvedResubmissionPolicy;
    workflow.savedApprovedResubmissionPolicy = apiWorkflow.approvedResubmissionPolicy;

    // Force resubmission policy value if necessary
    if (Selectors.shouldResubmissionPolicyBeDisabled(workflow)) forceResubmissionPolicyValue(workflow);
  });

  return state;
};

/**
 * Force the value of the workflow's approvedResubmissionPolicy value and set the flag indicating it is forced
 * @param workflow The ApprovalSettingsState workflow object on which to force the resubmission policy value
 * @returns The updated workflow
 */
export const forceResubmissionPolicyValue = (workflow: Types.WorkflowConfigurationData): Types.WorkflowConfigurationData => {
  workflow.isApprovedResubmissionPolicyForced = true;
  workflow.approvedResubmissionPolicy = Types.ApprovalEnums.ResubmissionSettings.MAINTAIN_STATE;

  return workflow;
};

/**
 * Reset the value of the workflow's approvedResubmissionPolicy value to its saved or default value and unset the flag indicating it is forced
 * @param workflow The ApprovalSettingsState workflow object on which to force the resubmission policy value
 * @returns The updated workflow
 */
export const resetResubmissionPolicyValue = (workflow: Types.WorkflowConfigurationData): Types.WorkflowConfigurationData => {
  workflow.isApprovedResubmissionPolicyForced = false;
  workflow.approvedResubmissionPolicy = (workflow.savedApprovedResubmissionPolicy || workflow.defaultApprovedResubmissionPolicy);

  return workflow;
};

/**
 * Force or reset the value of the workflow's approvedResubmissionPolicy value depending on whether it should be disabled or not
 * @param workflow The ApprovalSettingsState workflow object on which to force or reset the resubmission policy value
 * @returns The updated workflow
 */
export const forceOrResetResubmissionPolicyValue = (workflow: Types.WorkflowConfigurationData): Types.WorkflowConfigurationData => {
  return Selectors.shouldResubmissionPolicyBeDisabled(workflow) ?
    forceResubmissionPolicyValue(workflow) :
    resetResubmissionPolicyValue(workflow);
};

/**
 * Force or reset the value of the approvedResubmissionPolicy value for the given workflow if necessary
 * @param workflow The ApprovalSettingsState workflow object on which to potentially force or reset the resubmission policy value
 * @returns The updated workflow
 */
export const forceOrResetResubmissionPolicyValueIfNeeded = (workflow: Types.WorkflowConfigurationData): Types.WorkflowConfigurationData => {
  if (Selectors.shouldResubmissionPolicyBeDisabled(workflow)) {
    forceResubmissionPolicyValue(workflow);
  } else if (workflow.isApprovedResubmissionPolicyForced) {
    resetResubmissionPolicyValue(workflow);
  }

  return workflow;
};





/* Initial State
================================================================================ */
export const initialState: Types.ApprovalSettingsState = {
  actionButtonsDisabled: true,
  roles: [],
  apiWorkflows: [],
  changesAffectingPendingRequests: {
    tasks: [],
    workflows: []
  },
  isEdpDomain: Selectors.isEdpDomain(),
  saveConfirmationDialogOpen: false,
  editApproverDialogOpen: false,
  approverToEdit: undefined,
  users: [],
  workflows: {
    public: {
      approvedResubmissionPolicy: undefined,
      defaultApprovedResubmissionPolicy: Types.ApprovalEnums.ResubmissionSettings.RESUBMIT,
      enabled: true,
      isApprovedResubmissionPolicyForced: false,
      order: 1,
      savedApprovedResubmissionPolicy: undefined,
      targetAudience: Types.ApprovalEnums.WorkflowTargetAudience.PUBLIC,
      tasks: {
        community: {
          defaultValue: Types.ApprovalEnums.ApprovalState.PENDING,
          savedValue: undefined,
          scope: Types.ApprovalEnums.WorkflowTaskScope.COMMUNITY,
          targetAudience: Types.ApprovalEnums.WorkflowTargetAudience.PUBLIC,
          value: undefined,
        },
        official: {
          defaultValue: Types.ApprovalEnums.ApprovalState.PENDING,
          savedValue: undefined,
          scope: Types.ApprovalEnums.WorkflowTaskScope.OFFICIAL,
          targetAudience: Types.ApprovalEnums.WorkflowTargetAudience.PUBLIC,
          value: undefined,
        }
      }
    },
    internal: {
      approvedResubmissionPolicy: undefined,
      defaultApprovedResubmissionPolicy: Types.ApprovalEnums.ResubmissionSettings.RESUBMIT,
      enabled: Selectors.isEdpDomain(),
      isApprovedResubmissionPolicyForced: false,
      order: 2,
      savedApprovedResubmissionPolicy: undefined,
      targetAudience: Types.ApprovalEnums.WorkflowTargetAudience.INTERNAL,
      tasks: {
        community: {
          defaultValue: Types.ApprovalEnums.ApprovalState.PENDING,
          savedValue: undefined,
          scope: Types.ApprovalEnums.WorkflowTaskScope.COMMUNITY,
          targetAudience: Types.ApprovalEnums.WorkflowTargetAudience.INTERNAL,
          value: undefined,
        },
        official: {
          defaultValue: Types.ApprovalEnums.ApprovalState.PENDING,
          savedValue: undefined,
          scope: Types.ApprovalEnums.WorkflowTaskScope.OFFICIAL,
          targetAudience: Types.ApprovalEnums.WorkflowTargetAudience.INTERNAL,
          value: undefined,
        }
      }
    }
  }
};





/* Handlers
================================================================================ */
/**
 * Methods defined in this object are only called by the file's main reducer method and in tests.
 * They should never be called directly.
 *
 * Method names should follow the format: "handleAction_" + action.type
 */
export const handlers = {

  /**
   * Action handler method for the FETCH_SETTINGS_SUCCESS action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_FETCH_SETTINGS: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    const response = state;

    return response;
  },

  /**
   * Action handler method for the FETCH_SETTINGS_SUCCESS action
   * @param state The current value of state
   * @param apiResponse The returned value from /api/approvals
   * @returns A new, updated state instance
   */
  handleAction_FETCH_SETTINGS_SUCCESS: (
    state: Types.ApprovalSettingsState,
    apiResponse: { settings: Types.Approvals.Workflow[] }
  ): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      apiWorkflows: apiResponse.settings
    };
    const publicWorkflow = Selectors.getApiWorkflowByTargetAudience(response, Types.ApprovalEnums.WorkflowTargetAudience.PUBLIC);
    const internalWorkflow = Selectors.getApiWorkflowByTargetAudience(response, Types.ApprovalEnums.WorkflowTargetAudience.INTERNAL);

    response.users = mergeUsers(publicWorkflow?.approvers, internalWorkflow?.approvers, response.roles); // roles is forced to be done first in sagas file

    return populateApiSettings(response);
  },

  /**
   * Action handler method for the FETCH_SETTINGS_FAILURE action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_FETCH_SETTINGS_FAILURE: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    showForgeErrorToastNow(baseTranslateMethod('notifications.fetch_settings.error'));
    const response = {
      ...state
    };

    return response;
  },

  handleAction_FETCH_ROLES: (
    state: Types.ApprovalSettingsState,
    apiResponse: { roles: Types.Approvals.Role[] }
  ): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      roles: apiResponse.roles
    };

    return response;
  },

  handleAction_FETCH_ROLES_SUCCESS: (
    state: Types.ApprovalSettingsState,
    apiResponse: { roles: Types.Approvals.Role[] }
  ): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      roles: apiResponse.roles
    };

    return response;
  },

  /**
   * Action handler method for the FETCH_ROLES_FAILURE action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_FETCH_ROLES_FAILURE: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    showForgeErrorToastNow(baseTranslateMethod('notifications.fetch_users.error'));
    const response = {
      ...state
    };

    return response;
  },

  /**
   * Action handler method for the OPEN_EDIT_APPROVER action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_OPEN_EDIT_APPROVER: (state: Types.ApprovalSettingsState, { approver }): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      editApproverDialogOpen: true,
      approverToEdit: approver
    };

    return response;
  },

  /**
   * Action handler method for the CLOSE_EDIT_APPROVER action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_CLOSE_EDIT_APPROVER: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      editApproverDialogOpen: false,
      approverToEdit: undefined
    };

    return response;
  },

    /**
   * Action handler method for the EDIT_APPROVER_SUCCESS action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
    handleAction_EDIT_APPROVER_SUCCESS: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
      showForgeSuccessToastNow(baseTranslateMethod('notifications.edit_approver.success'));
      const response = {
        ...state,
        editApproverDialogOpen: false,
        approverToEdit: undefined
      };

      return response;
    },

  /**
   * Action handler method for the EDIT_APPROVER_FAILURE action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
    handleAction_EDIT_APPROVER_FAILURE: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
      showForgeErrorToastNow(baseTranslateMethod('notifications.edit_approver.error'));
      const response = {
        ...state,
        editApproverDialogOpen: false,
        approverToEdit: undefined
      };

      return response;
    },


  /**
   * Action handler method for the OPEN_APPROVAL_SETTINGS_SAVE_CONFIRMATION action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_OPEN_APPROVAL_SETTINGS_SAVE_CONFIRMATION: (state: Types.ApprovalSettingsState, { changesAffectingPendingRequests }): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      saveConfirmationDialogOpen: true,
      changesAffectingPendingRequests
    };

    return response;
  },

  /**
   * Action handler method for the CLOSE_APPROVAL_SETTINGS_SAVE_CONFIRMATION action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_CLOSE_APPROVAL_SETTINGS_SAVE_CONFIRMATION: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      changesAffectingPendingRequests: {
        tasks: [],
        workflows: []
      },
      saveConfirmationDialogOpen: false
    };

    return response;
  },

  /**
   * Action handler method for the SAVE_APPROVAL_SETTINGS action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_SAVE_APPROVAL_SETTINGS: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    const response = {
      ...state,
      actionButtonsDisabled: true,
      changesAffectingPendingRequests: {
        tasks: [],
        workflows: []
      },
      saveConfirmationDialogOpen: false,
    };

    return response;
  },

  /**
   * Action handler method for the SAVE_APPROVAL_SETTINGS_SUCCESS action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_SAVE_APPROVAL_SETTINGS_SUCCESS: (
    state: Types.ApprovalSettingsState,
    apiResponse: { settings: Types.Approvals.Workflow[] }
  ): Types.ApprovalSettingsState => {
    showForgeSuccessToastNow(baseTranslateMethod('notifications.save_settings.success'));
    const response = {
      ...state,
      actionButtonsDisabled: true,
      apiWorkflows: apiResponse.settings
    };

    return populateApiSettings(response);
  },

  /**
   * Action handler method for the SAVE_APPROVAL_SETTINGS_FAILURE action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_SAVE_APPROVAL_SETTINGS_FAILURE: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    showForgeErrorToastNow(baseTranslateMethod('notifications.save_settings.error'));
    const response = {
      ...state,
      actionButtonsDisabled: false,
    };

    return response;
  },

  /**
   * Action handler method for the RESET_APPROVAL_SETTINGS action
   * @param state The current value of state
   * @returns A new, updated state instance
   */
  handleAction_RESET_APPROVAL_SETTINGS: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    const response = resetCurrentSettings({
      ...state,
      actionButtonsDisabled: true,
    });

    return populateApiSettings(response);
  },

  /**
   * Action handler method for the UPDATE_APPROVAL_SETTINGS action
   * @param state The current value of state
   * @param params values needed to identify and update a specific setting value _{ sectionScope, itemScope, targetAudience, value }_
   * @returns A new, updated state instance
   */
  handleAction_UPDATE_APPROVAL_SETTINGS: (
    state: Types.ApprovalSettingsState,
    params: Types.UpdateApprovalSettingsActionParams
  ): Types.ApprovalSettingsState => {
    const {
      sectionScope,
      itemScope,
      targetAudience,
      value
    } = params;
    const response = {
      ...state
    };

    if (value) {
      const isResubmissionSettingUpdate = (itemScope === 'resubmission_policy');
      let keyPath;

      if (isResubmissionSettingUpdate) {
        // Resubmission policy settings are set at the workflow level
        keyPath = [targetAudience, 'approvedResubmissionPolicy'].join('.');
      } else {
        // All other settings are set at the task level
        keyPath = [targetAudience, 'tasks', sectionScope, 'value'].join('.');
      }

      set(response.workflows, keyPath, value);

      // Check if resubmission policy values need force-set/disabled or reset
      if (!isResubmissionSettingUpdate)
        forceOrResetResubmissionPolicyValueIfNeeded(response.workflows[targetAudience]);
    }

    // If we have changed settings, then the buttons should not be disabled
    response.actionButtonsDisabled = !Selectors.hasChangedSettings(response);

    return response;
  },

  handleAction_SAVE_NEW_APPROVERS_SUCCESS: (state: Types.ApprovalSettingsState, approversAdded: any) => {
    showForgeSuccessToastNow(baseTranslateMethod('notifications.add_rights.success'));
    const currentUsers = state.users;
    const response: Types.ApprovalSettingsState = {
      ...state,
      users: currentUsers.concat(approversAdded.approvers)
    };

    return response;
  },

  handleAction_SAVE_NEW_APPROVERS_FAILURE: (state: Types.ApprovalSettingsState) => {
    showForgeErrorToastNow(baseTranslateMethod('notifications.add_rights.error'));
    const response: Types.ApprovalSettingsState = {
      ...state
    };

    return response;
  },

  handleAction_REMOVE_ALL_APPROVAL_RIGHTS_SUCCESS: (state: Types.ApprovalSettingsState, sagaResponse: any): Types.ApprovalSettingsState => {
    showForgeSuccessToastNow(baseTranslateMethod('notifications.remove_rights.success'));
    const response = {
      ...state,
      users: filter(Selectors.getUsers(state), function(user) {return user.uid !== sagaResponse.uid;})
    };

    return response;
  },

  handleAction_REMOVE_ALL_APPROVAL_RIGHTS_FAILURE: (state: Types.ApprovalSettingsState): Types.ApprovalSettingsState => {
    showForgeErrorToastNow(baseTranslateMethod('notifications.remove_rights.error'));
    const response = {
      ...state,
    };

    return response;
  },
} as Types.ApprovalSettingsReducerHandlers;





/** Reducer
================================================================================ **/
/**
 * Generic reducer method which takes the current state and an action and routes to a handler method based on the action type.
 * @param state (Default: The initial state) The current value of state within the store.
 * @param action Redux action. The type attribute will be used to route the action's payload to the appropriate handler method.
 * @returns (ApprovalSettingsState) The result of the handler method for the action.type, or the current value of state if no handler method was found.
 */
const reducer = (state: Types.ApprovalSettingsState = initialState, action: AnyAction): Types.ApprovalSettingsState => {
  /**
   * 1) Prepend "handleAction_" to the action.type string to form the expected name of an action handler method
   *    Example: (MY_AWESOME_ACTION_TYPE -> handleAction_MY_AWESOME_ACTION_TYPE)
   * 2) Take the resulting method name string and see if a method with that name exists in the handlers object above.
   */
  const handlerMethod = handlers['handleAction_' + action.type];

  // If found, call the handler method with the current state and action.payload. Otherwise, return the current value of state.
  const response = typeof handlerMethod === 'function' ? handlerMethod(state, action.payload) : state;

  return response;
};

export default reducer;
