/* Imports
================================================================================ */
import { Approver, Role, Workflow } from 'common/types/approvals';
import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import * as Types from './types';





/* Actions
================================================================================ */

/* Settings
---------------------------------------------------------------------- */

/* Load
------------------------------------------------------------ */
// Called to make API call to get approvals settings
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const fetchSettings = (): AnyAction => {
  return action(FETCH_SETTINGS, {});
};

// Report success from API call
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const fetchSettingsSuccess = (settings: Workflow[]): AnyAction => {
  return action(FETCH_SETTINGS_SUCCESS, { settings });
};

// Report failure from API call
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
export const fetchSettingsFailure = (response: any): AnyAction => {
  return action(FETCH_SETTINGS_FAILURE, { response });
};



/* Reset
------------------------------------------------------------ */
// Called to reset the default settings values
export const RESET_APPROVAL_SETTINGS = 'RESET_APPROVAL_SETTINGS';
export const resetApprovalSettings = (): AnyAction => {
  return action(RESET_APPROVAL_SETTINGS, {});
};



/* Update
------------------------------------------------------------ */
// Called to update a given setting's value
export const UPDATE_APPROVAL_SETTINGS = 'UPDATE_APPROVAL_SETTINGS';
export const updateApprovalSettings = (params: Types.UpdateApprovalSettingsActionParams): AnyAction => {
  return action(UPDATE_APPROVAL_SETTINGS, params);
};



/* Save
------------------------------------------------------------ */
// Opens the save confirmation dialog
export const OPEN_APPROVAL_SETTINGS_SAVE_CONFIRMATION = 'OPEN_APPROVAL_SETTINGS_SAVE_CONFIRMATION';
export const openApprovalSettingsSaveConfirmation = (changesAffectingPendingRequests: Types.ChangedSettings): AnyAction => {
    return action(OPEN_APPROVAL_SETTINGS_SAVE_CONFIRMATION, { changesAffectingPendingRequests });
};

// Closes the save confirmation dialog
export const CLOSE_APPROVAL_SETTINGS_SAVE_CONFIRMATION = 'CLOSE_APPROVAL_SETTINGS_SAVE_CONFIRMATION';
export const closeApprovalSettingsSaveConfirmation = (): AnyAction => {
    return action(CLOSE_APPROVAL_SETTINGS_SAVE_CONFIRMATION, {});
};

// Saves the current approval settings values
export const SAVE_APPROVAL_SETTINGS = 'SAVE_APPROVAL_SETTINGS';
export const saveApprovalSettings = (): AnyAction => {
    return action(SAVE_APPROVAL_SETTINGS, {});
};

// Report successful save of current approval settings values
export const SAVE_APPROVAL_SETTINGS_SUCCESS = 'SAVE_APPROVAL_SETTINGS_SUCCESS';
export const saveApprovalSettingsSuccess = (settings: Workflow[]): AnyAction => {
    return action(SAVE_APPROVAL_SETTINGS_SUCCESS, { settings });
};

// Report failed save of current approval settings values
export const SAVE_APPROVAL_SETTINGS_FAILURE = 'SAVE_APPROVAL_SETTINGS_FAILURE';
export const saveApprovalSettingsFailure = (error: any): AnyAction => {
    return action(SAVE_APPROVAL_SETTINGS_FAILURE, { error });
};


/* Edit Approver
------------------------------------------------------------ */
// Opens the edit approver dialog
export const OPEN_EDIT_APPROVER = 'OPEN_EDIT_APPROVER';
export const openEditApprover = (approver: Types.Approvals.Approver): AnyAction => {
    return action(OPEN_EDIT_APPROVER, { approver });
};

// Closes the edit approver dialog
export const CLOSE_EDIT_APPROVER = 'CLOSE_EDIT_APPROVER';
export const closeEditApprover = (): AnyAction => {
    return action(CLOSE_EDIT_APPROVER, {});
};

// Kick off work to actually update the permissions on this approver
export const EDIT_APPROVER = 'EDIT_APPRPOVER';
export const editApprover = (oldApprover: Types.Approvals.Approver, newApprover: Types.Approvals.Approver) => {
  return action(EDIT_APPROVER, {oldApprover, newApprover});
};
export type EditApproverAction = ReturnType<typeof editApprover>;

// Report successful edit of approver
export const EDIT_APPROVER_SUCCESS = 'EDIT_APPROVER_SUCCESS';
export const editApproverSuccess = (): AnyAction => {
  return action(EDIT_APPROVER_SUCCESS, {});
};

// Report failure to edit approver
export const EDIT_APPROVER_FAILURE = 'EDIT_APPROVER_FAILURE';
export const editApproverFailure = (): AnyAction => {
  return action(EDIT_APPROVER_FAILURE);
};

/* Roles
---------------------------------------------------------------------- */
// Called to make API call to get all user roles n domain
export const FETCH_ROLES = 'FETCH_ROLES';
export const fetchRoles = (): AnyAction => {
  return action(FETCH_ROLES, {});
};

// Report success from API call
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const fetchRolesSuccess = (roles: Role[]): AnyAction => {
  return action(FETCH_ROLES_SUCCESS, { roles });
};

// Report failure from API call
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';
export const fetchRolesFailure = (response: any): AnyAction => {
  return action(FETCH_ROLES_FAILURE, { response });
};




/* User Rights
---------------------------------------------------------------------- */
// Remove both public and internal approval rights from a user
export const REMOVE_ALL_APPROVAL_RIGHTS = 'REMOVE_ALL_APPROVAL_RIGHTS';
export const removeAllApprovalRights = (approver: Approver) => action(REMOVE_ALL_APPROVAL_RIGHTS, {approver});
export type RemoveAllApprovalRightsAction = ReturnType<typeof removeAllApprovalRights>;

// Report success of API call
export const REMOVE_ALL_APPROVAL_RIGHTS_SUCCESS = 'REMOVE_ALL_APPROVAL_RIGHTS_SUCCESS';
export const removeAllApprovalRightsSuccess = (uid: string): AnyAction => {
  return action(REMOVE_ALL_APPROVAL_RIGHTS_SUCCESS, {uid});
};

// Report failure of API call
export const REMOVE_ALL_APPROVAL_RIGHTS_FAILURE = 'REMOVE_ALL_APPROVAL_RIGHTS_FAILURE';
export const removeAllApprovalRightsFailure = (): AnyAction => {
  return action(REMOVE_ALL_APPROVAL_RIGHTS_FAILURE, {});
};

// Add multiple users as approvers with their respective rights
export const SAVE_NEW_APPROVERS = 'SAVE_NEW_APPROVERS';
export const saveNewApprovers = (approvers: Approver[]) => action(SAVE_NEW_APPROVERS, {approvers});
export type SaveNewApproversAction = ReturnType<typeof saveNewApprovers>;

// Report success of API call
export const SAVE_NEW_APPROVERS_SUCCESS = 'SAVE_NEW_APPROVERS_SUCCESS';
export const saveNewApproversSuccess = (approvers: Approver[]): AnyAction => {
  return action(SAVE_NEW_APPROVERS_SUCCESS, {approvers});
};

// Report failure of API call
export const SAVE_NEW_APPROVERS_FAILURE = 'SAVE_NEW_APPROVERS_FAILURE';
export const saveNewApproversFailure = (): AnyAction => {
  return action(SAVE_NEW_APPROVERS_FAILURE, {});
};
