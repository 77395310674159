export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const fetchSettingsSuccess = (response) => ({ type: FETCH_SETTINGS_SUCCESS, response });

export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
export const fetchSettingsFailure = (response) => ({ type: FETCH_SETTINGS_FAILURE, response });

export const SELECT_PROVENANCE_CONFIG = 'SELECT_PROVENANCE_CONFIG';
export const selectProvenanceConfig =
  (provenance, value) => ({ type: SELECT_PROVENANCE_CONFIG, provenance, value });

export const SELECT_RESUBMISSION_CONFIG = 'SELECT_RESUBMISSION_CONFIG';
export const selectResubmissionConfig = (value) => ({ type: SELECT_RESUBMISSION_CONFIG, value });

export const EDITING_ENABLED = 'EDITING_ENABLED';
export const editingEnabled = { type: EDITING_ENABLED };

export const EDITING_CANCELLED = 'EDITING_CANCELLED';
export const editingCancelled = { type: EDITING_CANCELLED };

export const SAVING_STARTED = 'SAVING_STARTED';
export const savingStarted = { type: SAVING_STARTED };

export const SAVING_SUCCESSFUL = 'SAVING_SUCCESSFUL';
export const savingSuccessful = { type: SAVING_SUCCESSFUL };

export const SAVING_FINISHED = 'SAVING_FINISHED';
export const savingFinished = { type: SAVING_FINISHED };
