/* Imports
================================================================================ */
import React, { FC } from 'react';

import * as Types from '../../types';
import { useApprovalSettingsContext } from '../../contexts/ApprovalSettingsContext';
import Section from '../Section';
import { ApprovalsProcessConfigurationItem } from '../ConfigurationItem';





/* Predefined Values
================================================================================ */
export const sectionScope: Types.SectionScope = 'official';





/* Helper Methods
================================================================================ */





/* Components
================================================================================ */
const OfficialAssetsSection: FC<Types.OfficialAssetsSectionProps> = (props) => {
  const { getTranslateMethod } = useApprovalSettingsContext();
  const translate = getTranslateMethod('sections.' + sectionScope);
  const sectionProps = {
    ...props,
    title: translate('title'),
    icon: 'account_balance',
    classNames: sectionScope + '-section',
    description: '',
  };
  const configurationItemProps = {
    ...props,
    sectionScope: sectionScope,
    title: translate('items.approvals_process.title')
  };

  return (
    <Section { ...sectionProps }>
      <ApprovalsProcessConfigurationItem { ...configurationItemProps } />
    </Section>
  );
};

export default OfficialAssetsSection;
