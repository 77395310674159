/* Imports
================================================================================ */
import React, { FC } from 'react';
import { ForgeIcon } from '@tylertech/forge-react';

import * as Types from '../types';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */





/* Component
================================================================================ */
export const SectionHeader: FC<Types.SectionHeaderProps> = (props: Types.SectionHeaderProps) => {
  const {
    icon,
    title
  } = props;

  return (
    <div className='approval-settings-section-header forge-typography--subtitle1-secondary'>
      <ForgeIcon name={icon}/>
      <span className='approval-settings-section-title'>{title}</span>
    </div>
  );
};

export const SectionDescription: FC<Pick<Types.SectionProps, 'description'>> = ({ description }) => {
  let response = null;

  if (description) {
    response = (
      <div className="approval-settings-section-description">{description}</div>
    );
  }

  return response;
};

const Section: FC<Types.SectionProps> = (props) => {
  const {
    title,
    icon,
    description,
    classNames = '',
    children
  } = props;
  const sectionHeaderProps = { title, icon };
  const sectionDescriptionProps = { description };

  return (
    <div className={`approval-settings-section ${classNames}`}>
      <SectionHeader { ...sectionHeaderProps } />

      <div className="approval-settings-section-body">
        <SectionDescription { ...sectionDescriptionProps } />

        {children}
      </div>
    </div>
  );
};

export default Section;
