/* Imports
================================================================================ */
import React, { ChangeEvent, FC, useEffect, useRef } from 'react';
import { ForgeRadio, ForgeSwitch } from '@tylertech/forge-react';

import { ApprovalState, ResubmissionSettings } from 'common/core/approvals_enums';
import { ForgeSwitchSelectEventHandlerFunction } from 'common/tyler_forge/types/ForgeSwitch';

import * as Types from '../types';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */
/**
 * Determines which input component to render based on the provided type
 * @param type The input type to use in determining which input component to return
 * @returns ConfigurationOptionRadioInput or ConfigurationOptionSwitchInput
 */
const getInputComponent = (type: string): FC<Types.ConfigurationOptionInputProps> => {
  let InputComponent = ConfigurationOptionRadioInput;

  if (type === 'switch') {
    InputComponent = ConfigurationOptionSwitchInput;
  }

  return InputComponent;
};

/**
 * Method to use in conjunction with React's useEffect for listening to the change event from a ForgeSwitch.
 *  Extrapolated to avoid clogging the ConfigurationOptionSwitchInput component
 * @param switchRef Reference object for the ForgeSwitch we want to listen to
 * @param changeHandler The method to call when the switch has experienced a change event
 * @param selectedValue The value to pass to changeHandler if the switch will be selected once the event has resolved
 * @param unselectedValue The value to pass to changeHandler if the switch will be unselected once the event has resolved
 * @returns a function to remove the event listener.
 */
const switchEventListenerEffect = (
  switchRef: Types.ReferenceObject,
  changeHandler: Types.DispatchedActionMethod,
  selectedValue?: ApprovalState | ResubmissionSettings,
  unselectedValue?: ApprovalState | ResubmissionSettings
) => {
  const switchEl = switchRef.current;
  const handleChange: ForgeSwitchSelectEventHandlerFunction = (ev) => {
    ev.stopPropagation();

    /** NOTE - This is somewhat counter intuitive!
     * The value of ev.target.selected will be the selected value the switch had BEFORE the event was initiated,
     * NOT the value it will have after the event has resolved.
     *
     * So:
     * - IF the switch was selected before it was clicked:
     *   - THEN ev.target.selected will be TRUE.
     * - IF the switch was unselected before it was clicked:
     *   - THEN ev.target.selected will be FALSE.
     *
     * So, the value we need to pass on has to be logically swapped as well:
     * - WHEN ev.target.selected is TRUE (because the switch was selected when the event was initiated):
     *   - THEN pass the unselectedValue (because the switch will be unselected when the event has resolved)
     * - WHEN ev.target.selected is FALSE (because the switch was unselected when the event was initiated):
     *   - THEN pass the selectedValue (because the switch will be selected when the event has resolved)
     */
    if (ev.target) changeHandler(ev.target.selected ? unselectedValue : selectedValue);
  };

  switchEl?.addEventListener('forge-switch-select', handleChange);

  return () => switchEl?.removeEventListener('forge-switch-select', handleChange);
};





/* Components
================================================================================ */
export const ConfigurationOptionInputLabel: FC<Types.ConfigurationOptionInputLabelProps> = (props) => {
  const {
    id,
    label
  } = props;

  return (
    <label className="approval-settings-configuration-option-input-label" htmlFor={id}>
      {label}
    </label>
  );
};

export const ConfigurationOptionRadioInput: FC<Types.ConfigurationOptionInputProps> = (props) => {
  const {
    disabled,
    id,
    label,
    name,
    currentValue,
    value,
    changeHandler,
  } = props;
  const labelProps = { id, label };
  const inputRef: Types.ReferenceObject = useRef(null);

  /**
   * Some not very clear, but VERY important reasons why the onClick, checked, and readOnly props need to be used here:
   *
   * onClick instead of onChange and checked instead of defaultChecked
   * ----------------------------------------------------------------------
   * We need to use onClick here instead of onChange because onChange causes issues when the
   * reset action is called and the store/state is changed. After 2.5 days of fighting with this,
   * here is my understanding of why:
   *
   * When the reset to the values in the store/state occurs and we re-render,
   * the UI will update to show the radio corresponding to the reset value from the store/state as checked.
   * However, the radio group's internal pointer for what the group's currently set value is does NOT update.
   * It still points to the value from before the reset. This means that after a reset,
   * if you click on the radio which was checked prior to the reset,
   * the logical check for onChange looks at the radio group's currently set value
   * and sees it is equal to the value of the radio you just clicked on.
   * Because they are equal, no onChange event fires, even though the clicked radio will now show as checked.
   * Because no onChange event fired, the update action is never dispatched and the store/state never gets changed.
   * So now the store/state value is one thing and the radio showing as checked is a different value.
   *
   * Using onClick instead of onChange means that we ignore the radio group's internal pointer value and
   * just always use the store/state value and the individual radio input's value to determine
   * which radio should be checked and when we need to dispatch an update action to the store/state.
   * Because we are only going to use the individual radio input value and the store/state value,
   * we need to use the checked prop to determine which radio should currently be checked.
   * The defaultChecked prop still experiences issues with the reset action so we have to use the checked prop
   * to get the functionality we want.
   *
   *
   * readOnly
   * ----------------------------------------------------------------------
   * If you try and render a radio input without an onChange prop, react complains in the console that
   * you either need to use the defaultChecked prop instead of the checked prop or you need to set the
   * readOnly prop to true. As discussed above, we can't use the defaultChecked prop, we have to use the checked prop.
   * Because of that, we have to set the readOnly prop to true to avoid React complaining.
   */
  return (
    <ForgeRadio>
      <input
        ref={inputRef}
        type="radio"
        className="approval-settings-configuration-option-input"
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        readOnly={true}
        checked={(currentValue === value)}
        onClick={() => {
          if (inputRef.current) changeHandler(inputRef.current.value as ApprovalState | ResubmissionSettings);
        }} />
      <ConfigurationOptionInputLabel { ...labelProps } />
    </ForgeRadio>
  );
};

export const ConfigurationOptionSwitchInput: FC<Types.ConfigurationOptionInputProps> = (props) => {
  const {
    name,
    disabled,
    currentValue,
    selectedValue,
    unselectedValue,
    changeHandler,
  } = props;
  const switchProps = {
    disabled,
    name,
    selected: (currentValue === selectedValue)
  };
  const switchRef: Types.ReferenceObject = useRef(null);

  useEffect(
    () => switchEventListenerEffect(switchRef, changeHandler, selectedValue, unselectedValue),
    [switchRef, changeHandler, selectedValue, unselectedValue]
  );

  return (<ForgeSwitch { ...switchProps } ref={switchRef} />);
};

const ConfigurationOption: FC<Types.ConfigurationOptionProps> = (props) => {
  const { actions: { updateApprovalSettings } } = useApprovalSettingsContext();
  const {
    disabled,
    inputs,
    name,
    targetAudience,
    sectionScope,
    itemScope,
    type,
    value,
  } = props;
  const InputComponent = getInputComponent(type);

  if (!inputs.length) {
    return null;
  }

  return (
    <div className="approval-settings-configuration-option" role={type === 'radio' ? 'radiogroup' : ''}>
      {inputs.map((input) => {
        const inputProps = {
          ...input,
          currentValue: value,
          disabled,
          name,
          id: [name, (input.value || 'switch')].join('-'),
          changeHandler: (newValue: ApprovalState | ResubmissionSettings) => {
            updateApprovalSettings({
              sectionScope,
              itemScope,
              targetAudience,
              value: newValue
            });
          },
        };

        return (
          <div className="approval-settings-configuration-option-input-wrapper" key={inputProps.id}>
            <InputComponent { ...inputProps } />
          </div>
        );
      })}
    </div>
  );
};

export default ConfigurationOption;
