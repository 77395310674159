import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ForgeIcon } from '@tylertech/forge-react';
import { fetchSettings } from 'common/core/approvals';

import * as actions from './actions';
import I18n from 'common/i18n/index';
import Introduction from './components/Introduction';
import Actions from './components/Actions';
import Approvers from './components/Approvers';
import AssetUpdateConfig from './components/AssetUpdateConfig';
import CommunityAssetConfig from './components/CommunityAssetConfig';
import OfficialAssetConfig from './components/OfficialAssetConfig';
import { APPROVALS } from 'common/core/approvals_enums';

import 'frontend/app/styles/administration/approvals/settings.scss';

const translationScope = 'shared.approvals.settings';

const { APPROVED, PENDING, REJECTED } = APPROVALS.STATUS;

export const SettingsUi = () => {
  return (
    <div className="approval-settings-v1">
      <div className="approval-settings-container">
        <div className="approval-settings-title-actions">
          <h3 className="approval-settings-title">{I18n.t('title', { scope: translationScope })}</h3>
          <Actions />
        </div>

        <div className="approval-settings-section">
          <Introduction />
        </div>

        <div className="approval-settings-section">
          <OfficialAssetConfig options={[APPROVED, PENDING]} />
        </div>

        <div className="approval-settings-section">
          <CommunityAssetConfig options={[APPROVED, PENDING, REJECTED]} />
        </div>

        <div className="approval-settings-section">
          <AssetUpdateConfig />
        </div>

        <div className="approval-settings-section">
          <Approvers />
        </div>

        <div className="learn_more">
          <ForgeIcon name="info" />
          <a href="https://support.socrata.com/hc/en-us/articles/360021671013-Configuring-Approvals">
            {I18n.t('learn_more', { scope: translationScope })}
          </a>
        </div>
      </div>
    </div>
  );
};

class Settings extends Component {
  componentDidMount() {
    const { fetchSettingsSuccess, fetchSettingsFailure } = this.props;

    fetchSettings()
      .then((res) => {
        fetchSettingsSuccess(res);
      })
      .catch((error) => {
        fetchSettingsFailure(error);
      });
  }

  render() {
    return <SettingsUi />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchSettingsSuccess: (response) => dispatch(actions.fetchSettingsSuccess(response)),
  fetchSettingsFailure: (response) => dispatch(actions.fetchSettingsFailure(response))
});

export default connect(null, mapDispatchToProps)(Settings);
