import React from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import * as constants from 'common/components/AssetBrowser/lib/constants';

const translationScope = 'shared.approvals.settings';
const { AUTHORITY_COMMUNITY, AUTHORITY_OFFICIAL, ASSET_UPDATES, APPROVERS } = constants;
const validSections = [AUTHORITY_COMMUNITY, AUTHORITY_OFFICIAL, ASSET_UPDATES, APPROVERS];

const ConfigHeader = (props) => {
  const { section } = props;
  const title = I18n.t(`${section}.title`, { scope: translationScope });

  const iconNameMap = {
    official: 'account_balance',
    community: 'groups',
    asset_updates: 'update',
    approvers: 'how_to_reg'
  };

  const icon = iconNameMap[section] && (
    <ForgeIcon name={iconNameMap[section]} class="approval-settings-section-title-icon" />
  );

  return (
    <div className="approval-settings-section-title">
      {icon}
      <span className="approval-settings-section-title-text">{title}</span>
    </div>
  );
};

ConfigHeader.propTypes = {
  section: PropTypes.oneOf(validSections).isRequired
};

export default ConfigHeader;
