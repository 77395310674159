import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import I18n from 'common/i18n/index';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import Checkbox from 'common/components/Checkbox';
import { APPROVALS } from 'common/core/approvals_enums';

import * as actions from 'frontend/public/javascripts/approvals/components/Settings/actions';
import ConfigWarning from './ConfigWarning';
import ConfigHeader from './ConfigHeader';
import { AFFECTED_SCOPE } from '../constants';

const { RESUBMIT, MAINTAIN_STATE } = APPROVALS.RESUBMISSION_SETTINGS;
const translationScope = 'shared.approvals.settings';

const AssetUpdateConfig = (props) => {
  const { enabled, onClick, selected } = props;

  const classes = classNames('config-option', 'asset_update_option', { selected, disabled: !enabled });

  return (
    <div className='asset_update'>
      <ConfigHeader section={constants.ASSET_UPDATES}/>

      <div className="approval-settings-section-body">
        <div className="approval-settings-section-description">
          {I18n.t('asset_updates.subtitle', {scope: translationScope})}
        </div>

        <div className="approval-settings-section-configuration-options">
          <li className={classes} onClick={onClick(selected)}>
            <form>
              <Checkbox id="approval-configuration-reapproval"
                        checked={selected}
                        onChange={() => {}}/>

              <div className='checkbox_label' htmlFor="approval-configuration-reapproval">
                {I18n.t('asset_updates.require_reapproval', { scope: translationScope })}
              </div>
            </form>
          </li>
        </div>
      </div>


      <ConfigWarning
        hidden={!props.showWarning}
        affectedScope={AFFECTED_SCOPE.UPDATE_REQUESTS}
        outcome={APPROVALS.STATUS.PENDING}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  showWarning: changingFromPending(state),
  enabled: state.assetUpdateConfigEnabled && state.editing,
  selected: state.approvalWorkflow.approvedResubmissionPolicy === RESUBMIT
});

const changingFromPending = state => {
  const {
    approvedResubmissionPolicy: unsaved,
    currentApprovedResubmissionPolicy: current
  } = state.approvalWorkflow;

  return current === APPROVALS.RESUBMISSION_SETTINGS.RESUBMIT && current !== unsaved;
};

const mapDispatchToProps = (dispatch) => ({
  onClick: (selected) => () => dispatch(actions.selectResubmissionConfig(toggleSelection(selected)))
});

const toggleSelection = (isSelected) => isSelected ? MAINTAIN_STATE : RESUBMIT;

export default connect(mapStateToProps, mapDispatchToProps)(AssetUpdateConfig);
