/* Imports
================================================================================ */
import React, { FC, useState } from 'react';
import {
  ForgeCheckbox
} from '@tylertech/forge-react';
import * as Types from '../types';


/* Component
A little component that forces re-rendering to happen. When just using the ForgeCheckbox without this component, we get
weird bad behavior in the Add Approvers modal.
================================================================================ */
const AddApproverCheckbox: FC<Types.AddApproverCheckboxAcceptedProps> = (props: Types.AddApproverCheckboxAcceptedProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(props.checked);

    const handleCheckboxChange = (e: React.MouseEvent<HTMLElement>) => {
        const event = e as any;
        setIsChecked(event.target.checked);
        if (props.onCheckboxChanged) {
            props.onCheckboxChanged(e);
        }
      };

    return (
        <ForgeCheckbox>
          <input type="checkbox" id={`can-review-${props.audience}-checkbox_${props.rowIndex}`} data-audience={props.audience} data-email={props.email} checked={isChecked} onClick={handleCheckboxChange} readOnly={true} />
        </ForgeCheckbox>
      );
};

export default AddApproverCheckbox;
