import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n/index';
import SocrataIcon from 'common/components/SocrataIcon';
import { APPROVALS as constants } from 'common/core/approvals_enums';

import { AFFECTED_SCOPE } from '../constants';

const translationScope = 'shared.approvals.settings.warning';

const ConfigWarning = (props) => {
  const {
    affectedScope,
    outcome,
    hidden,
  } = props;

  if (hidden) { return null; }

  const warning = ['intro', affectedScope, outcome]
    .map(i18nKey => I18n.t(i18nKey, { scope: translationScope }))
    .join(' ');

  return (
    <div className='config-warning alert warning'>
      <SocrataIcon name="warning" />
      <span className='config-warning-text'>{warning}</span>
    </div>
  );
};

ConfigWarning.propTypes = {
  affectedScope: PropTypes.oneOf(Object.values(AFFECTED_SCOPE)),
  outcome: PropTypes.oneOf([constants.STATUS.APPROVED, constants.STATUS.REJECTED, constants.STATUS.PENDING]),
  hidden: PropTypes.bool,
};

export default ConfigWarning;
