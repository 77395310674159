/* Imports
================================================================================ */
import React, { FC, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import { Workflow, Role, Approver } from 'common/types/approvals';
import getScopedTranslateMethod from 'common/js_utils/getScopedTranslateMethod';
import * as Actions from '../actions';
import { initialState } from '../reducer';
import * as Types from '../types';





/* Predefined Values
================================================================================ */
export const baseTranslateMethod = getScopedTranslateMethod('shared.approvals.settings_v2');
export const getTranslateMethod = baseTranslateMethod.getScopedTranslateMethod;
export const ApprovalSettingsContext = createContext<Types.ApprovalSettingsContext>({
  actions: {},
  state: initialState,
  dispatch: () => initialState,
  getTranslateMethod,
  translate: baseTranslateMethod,
});
let preDispatchedActions: Types.ApprovalSettingsContextActions;





/* Helper Methods
================================================================================ */
/**
 * Supplies an object containing the action methods already set up to dispatch when called
 * @param dispatch The store's dispatch method
 * @returns ApprovalSettingsContextActions
 */
export const useActions = (dispatch: Types.DispatchMethod): Types.ApprovalSettingsContextActions => {
  return preDispatchedActions || (preDispatchedActions = {
    closeApprovalSettingsSaveConfirmation: () => dispatch(Actions.closeApprovalSettingsSaveConfirmation()),
    closeEditApproverDialog: () => dispatch(Actions.closeEditApprover()),
    editApprover: (oldApprover: Types.Approvals.Approver, newApprover: Types.Approvals.Approver) => dispatch(Actions.editApprover(oldApprover, newApprover)),
    fetchRoles: () => dispatch(Actions.fetchRoles()),
    fetchRolesSuccess: (roles: Role[]) => dispatch(Actions.fetchRolesSuccess(roles)),
    fetchRolesFailure: (response: any) => dispatch(Actions.fetchSettingsFailure(response)),
    fetchSettings: () => dispatch(Actions.fetchSettings()),
    fetchSettingsSuccess: (settings: Workflow[]) => dispatch(Actions.fetchSettingsSuccess(settings)),
    fetchSettingsFailure: (response: any) => dispatch(Actions.fetchSettingsFailure(response)),
    openApprovalSettingsSaveConfirmation: (changes: Types.ChangedSettings) => dispatch(Actions.openApprovalSettingsSaveConfirmation(changes)),
    openEditApproverDialog: (approver: Types.Approvals.Approver) => dispatch(Actions.openEditApprover(approver)),
    resetApprovalSettings: () => dispatch(Actions.resetApprovalSettings()),
    saveApprovalSettings: () => dispatch(Actions.saveApprovalSettings()),
    updateApprovalSettings: (params: Types.UpdateApprovalSettingsActionParams) => dispatch(Actions.updateApprovalSettings(params)),
    saveNewApprovers: (approvers: Approver[]) => dispatch(Actions.saveNewApprovers(approvers)),
    removeAllApprovalRights: (approver: Approver) => dispatch(Actions.removeAllApprovalRights(approver))
  });
};

/**
 * Method that allows components to gain access to the ApprovalSettingsContext object as long as they are within a ApprovalSettingsContext.Provider
 * @returns ApprovalSettingsContext (the current value)
 */
export const useApprovalSettingsContext = () => {
  const context = useContext(ApprovalSettingsContext);
  if (!context) {
    throw new Error('useApprovalSettingsContext must be used within a ApprovalSettingsProvider');
  }

  return context;
};





/* Components
================================================================================ */
const mapStateToProps = (state: Types.ApprovalSettingsState): { state: Types.ApprovalSettingsState } => ({
  state
});

const ApprovalSettingsContextProvider: FC<Types.ApprovalSettingsProviderProps> = (props) => {
  const {
    dispatch,
    state,
    translate,
    children
  } = props;
  const actions = useActions(dispatch);
  const value = {
    actions,
    state,
    dispatch,
    getTranslateMethod,
    translate: translate || baseTranslateMethod,
  };

  return <ApprovalSettingsContext.Provider value={value}>{children}</ApprovalSettingsContext.Provider>;
};

export const ApprovalSettingsProvider: FC<Types.ApprovalSettingsProviderProps> = connect(mapStateToProps)(ApprovalSettingsContextProvider);
