/* Imports
================================================================================ */
import React, { FC } from 'react';

import * as Types from '../../types';
import { useApprovalSettingsContext } from '../../contexts/ApprovalSettingsContext';
import Section from '../Section';
import { ApprovalsProcessConfigurationItem } from '../ConfigurationItem';





/* Predefined Values
================================================================================ */
export const sectionScope: Types.SectionScope = 'community';





/* Helper Methods
================================================================================ */





/* Component
================================================================================ */
const CommunityAssetsSection: FC<Types.CommunityAssetsSectionProps> = (props) => {
  const { getTranslateMethod, state: { isEdpDomain } } = useApprovalSettingsContext();
  const translate = getTranslateMethod('sections.' + sectionScope);
  let response: JSX.Element | null = null;

  // EDP Domains shouldn't display a community assets section
  if (!isEdpDomain) {
    const sectionProps = {
      ...props,
      title: translate('title'),
      icon: 'groups',
      classNames: sectionScope + '-section',
      description: '',
    };
    const configurationItemProps = {
      ...props,
      sectionScope: sectionScope,
      title: translate('items.approvals_process.title')
    };

    response = (
      <Section { ...sectionProps }>
        <ApprovalsProcessConfigurationItem { ...configurationItemProps } />
      </Section>
    );
  }

  return response;
};

export default CommunityAssetsSection;
