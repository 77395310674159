/* Imports
================================================================================ */
import React, { FC } from 'react';
import {sortBy} from 'lodash';

import { CancelActionButton, SaveActionButton } from './ActionButton';
import * as Selectors from '../selectors';
import * as Types from '../types';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';





/* Predefined Values
================================================================================ */





/* Helper Methods
================================================================================ */
export const checkSaveConditions = (
  state: Types.ApprovalSettingsState,
  saveApprovalSettings: Types.DispatchedActionMethod,
  openApprovalSettingsSaveConfirmation: Types.DispatchedActionMethod
) => {
  const changedSettings = Selectors.getChangedSettings(state);
  const changesAffectingPendingRequests = Selectors.getChangesAffectingPendingRequests(changedSettings);

  return changesAffectingPendingRequests.tasks.length /* || changesAffectingPendingRequests.workflows.length */ ?
    openApprovalSettingsSaveConfirmation(changesAffectingPendingRequests) :
    saveApprovalSettings();
};






/* Components
================================================================================ */
export const IntroductionActions: FC = () => {
  const {
    actions: {
      resetApprovalSettings,
      saveApprovalSettings,
      openApprovalSettingsSaveConfirmation,
    },
    state
  } = useApprovalSettingsContext();
  const cancelButtonProps = {
    clickHandler: resetApprovalSettings,
    disabled: state.actionButtonsDisabled,
    slot: 'end'
  };
  const saveButtonProps = {
    clickHandler: () => checkSaveConditions(state, saveApprovalSettings, openApprovalSettingsSaveConfirmation),
    disabled: state.actionButtonsDisabled,
    slot: 'end'
  };

  return (
    <>
      <CancelActionButton { ...cancelButtonProps } />
      <SaveActionButton { ...saveButtonProps } />
    </>
  );
};

export const IntroductionWorkflowHeader: FC<Types.IntroductionWorkflowHeaderProps> = (props) => {
  const {
    header,
    enabled,
  } = props;
  let response = null;

  if (enabled) {
    response = (
      <h4 className="approval-settings-introduction-headers-workflow-header forge-typography--subtitle1-secondary">
        {header}
      </h4>
    );
  }

  return response;
};

export const IntroductionWorkflowHeaders: FC = () => {
  const { state: { workflows }, translate } = useApprovalSettingsContext();
  const sortedWorkflows: Types.WorkflowConfigurationData[] = sortBy(Object.values(workflows), 'order');

  return (
    <div className="approval-settings-introduction-headers">
      <div className="approval-settings-introduction-headers-spacer"></div>

      {sortedWorkflows.map((workflow) => {
        const props = {
          header: translate(`workflows.${workflow.targetAudience}_approvals`),
          enabled: workflow.enabled
        };

        return (<IntroductionWorkflowHeader { ...props } key={workflow.targetAudience} />);
      })}
    </div>
  );
};

const Introduction: FC = () => {
  const { translate } = useApprovalSettingsContext();

  return (
    <div className="approval-settings-introduction">

      <div className="approval-settings-title-actions">
        <h3 className="approval-settings-title forge-typography--headline5">
          {translate('title')}
        </h3>


        <div className="approval-settings-actions">
          <IntroductionActions />
        </div>
      </div>

      <IntroductionWorkflowHeaders />
    </div>
  );
};

export default Introduction;
