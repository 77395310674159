/* Imports
================================================================================ */
import React, { FC, useState } from 'react';
import {
    ForgeButton,
    ForgeCard,
    ForgeDialog,
    ForgeDialogOptions,
    ForgeIcon} from '@tylertech/forge-react';
import * as Types from '../types';
import ApproversTable from './ApproversTable';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';
import AddApproversModal from './AddApproversModal';
import EditApproversModal from './EditApproversModal';

/* Component
================================================================================ */
const ApproversSection: FC<Types.ApproversSectionAcceptedProps> = (props: Types.ApproversSectionAcceptedProps) => {
  const {
      state: {
        isEdpDomain,
        users,
        editApproverDialogOpen
      },
      actions: {
        closeEditApprover
      },
      getTranslateMethod,
  } = useApprovalSettingsContext();
    const approversTranslate = getTranslateMethod('sections.approvers');
    const subtitle = isEdpDomain ? approversTranslate('subtitle_edp') :  approversTranslate('subtitle_odp');
    const [isAddApproversOpen, setIsAddApproversOpen] = useState<boolean>(false);
    const showAddApprovers = () => setIsAddApproversOpen(true);
    const closeAddApproversDialog = () => {
        setIsAddApproversOpen(false);
    };

    const approversDialogOptions: ForgeDialogOptions = {
        dialogClass: 'approvers-dialog'
    };

    return (
        <div>
            <div className='approval-settings-section approvers-section'>
            <ForgeCard outlined={false} style={{'--forge-card-padding': '0px'}}>
            <div className='approvers-header-content'>
                <div className="forge-typography--headline5">
                    <ForgeIcon name='account_check' external external-type="standard" className="heading-icon"/>
                    {approversTranslate('title')}
                </div>
                <div>
                    <ForgeButton type='flat'>
                        <button onClick={() => location.href = '/admin/users'} data-testid='link-to-manage-users-button'>
                            {approversTranslate('actions.manage_users')}
                        </button>
                    </ForgeButton>
                </div>
            </div>
            <span className='approvers-subtitle'>{subtitle}</span>
            {
                isEdpDomain &&
                <div className='button-div'>
                <ForgeButton type='outlined'>
                    <button type='button' onClick={showAddApprovers} data-testid='add-approvers-button'>
                        <ForgeIcon name='add'/>
                        <span>{approversTranslate('actions.add_approvers')}</span>
                    </button>
                </ForgeButton>
                </div>
            }
            <ApproversTable tableType={Types.ApproversTableType.SETTINGS_PAGE} users={users} />
            </ForgeCard>
        </div>
        <ForgeDialog open={isAddApproversOpen} onDismiss={closeAddApproversDialog} options={approversDialogOptions}>
            <AddApproversModal closeDialogHandler={closeAddApproversDialog}/>
        </ForgeDialog>
        <ForgeDialog open={editApproverDialogOpen} onDismiss={closeEditApprover} options={approversDialogOptions}>
            <EditApproversModal closeDialogHandler={closeEditApprover}/>
        </ForgeDialog>
    </div>
    );
};

export default ApproversSection;
